import grapesjs from 'grapesjs';
import initComponents from './components';
import initUniversalComponents from './universal-components';
import careerumComponents from './components-careerum';
import loadBlocks from './blocks-careerum';


export default grapesjs.plugins.add('gjs-blocks-careerum4.0', (editor: any, opts: any = {}) => {
  const config = {
    category: 'Careerum 4',
    ...opts
  };

  const domc = editor.DomComponents;

  // Add components
  const addTypeFromClassName = initComponents(editor, config);
  addTypeFromClassName(careerumComponents);

  initUniversalComponents(editor, config);

  // Add blocks
  loadBlocks(editor, config);

  // Define new Trait type - Goals tabs
  const goalsTimeTabType = 'data-name';
  editor.TraitManager.addType(goalsTimeTabType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(goalsTimeTabType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[goalsTimeTabType] = newVal;
    }
  });

  const goalsTimeTabComponents = [
    'club40-time-item'
  ];
  let defaultAttributesGoalsTimeTab: any = {
    required: false
  };
  defaultAttributesGoalsTimeTab[goalsTimeTabType] = '';

  goalsTimeTabComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Offer ID',
              name: goalsTimeTabType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesGoalsTimeTab
        }
      }

    })
  });

  // Define new Trait type - Tariffs settings
  const tariffsType = 'data-price';
  editor.TraitManager.addType(tariffsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(tariffsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[tariffsType] = newVal;
    }
  });

  const tariffsComponents = [
    'club40-tariffs-item'
  ];
  let defaultAttributesTariffs: any = {
    required: false
  };
  defaultAttributesTariffs[tariffsType] = '';

  tariffsComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена 1 мес Р',
              name: 'data-price-1m-rub',
              type: 'text',
            },
            {
              label: 'Цена 3 мес Р',
              name: 'data-price-3m-rub',
              type: 'text',
            },
            {
              label: 'Цена 3 мес полн Р',
              name: 'data-fullprice-3m-rub',
              type: 'text',
            },
            {
              label: 'Цена 6 мес Р',
              name: 'data-price-6m-rub',
              type: 'text',
            },
            {
              label: 'Цена 6 мес полн Р',
              name: 'data-fullprice-6m-rub',
              type: 'text',
            },
            {
              label: 'Цена 12 мес Р',
              name: 'data-price-12m-rub',
              type: 'text',
            },
            {
              label: 'Цена 12 мес полн Р',
              name: 'data-fullprice-12m-rub',
              type: 'text',
            },
            {
              label: 'Цена 1 мес €',
              name: 'data-price-1m-eur',
              type: 'text',
            },
            {
              label: 'Цена 3 мес €',
              name: 'data-price-3m-eur',
              type: 'text',
            },
            {
              label: 'Цена 3 мес полн €',
              name: 'data-fullprice-3m-eur',
              type: 'text',
            },
            {
              label: 'Цена 6 мес €',
              name: 'data-price-6m-eur',
              type: 'text',
            },
            {
              label: 'Цена 6 мес полн €',
              name: 'data-fullprice-6m-eur',
              type: 'text',
            },
            {
              label: 'Цена 12 мес €',
              name: 'data-price-12m-eur',
              type: 'text',
            },
            {
              label: 'Цена 12 мес полн €',
              name: 'data-fullprice-12m-eur',
              type: 'text',
            },
            {
              label: 'Цена 1 мес Р NEW',
              name: 'data-price-1m-rub-new',
              type: 'text',
            },
            {
              label: 'Цена 3 мес Р NEW',
              name: 'data-price-3m-rub-new',
              type: 'text',
            },
            {
              label: 'Цена 6 мес Р NEW',
              name: 'data-price-6m-rub-new',
              type: 'text',
            },
            {
              label: 'Цена 12 мес Р NEW',
              name: 'data-price-12m-rub-new',
              type: 'text',
            },
            {
              label: 'Цена 1 мес € NEW',
              name: 'data-price-1m-eur-new',
              type: 'text',
            },
            {
              label: 'Цена 3 мес € NEW',
              name: 'data-price-3m-eur-new',
              type: 'text',
            },
            {
              label: 'Цена 6 мес € NEW',
              name: 'data-price-6m-eur-new',
              type: 'text',
            },
            {
              label: 'Цена 12 мес € NEW',
              name: 'data-price-12m-eur-new',
              type: 'text',
            },
            {
              label: 'Оффер 1 мес P',
              name: 'data-offer-1m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 3 мес P',
              name: 'data-offer-3m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 6 мес P',
              name: 'data-offer-6m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 12 мес P',
              name: 'data-offer-12m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 1 мес €',
              name: 'data-offer-1m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 3 мес €',
              name: 'data-offer-3m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 6 мес €',
              name: 'data-offer-6m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 12 мес €',
              name: 'data-offer-12m-eur',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTariffs
        }
      }

    })
  });

  // Define new Trait type - Tariffs course settings
  const tariffsCourseType = 'data-price';
  editor.TraitManager.addType(tariffsCourseType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(tariffsCourseType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[tariffsCourseType] = newVal;
    }
  });

  const tariffsCourseComponents = [
    'course40-tariffs-item'
  ];
  let defaultAttributesTariffsCourse: any = {
    required: false
  };
  defaultAttributesTariffsCourse[tariffsCourseType] = '';

  tariffsCourseComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена ₽',
              name: 'data-price-rub',
              type: 'text',
            },
            {
              label: 'Цена €',
              name: 'data-price-eur',
              type: 'text',
            },
            {
              label: 'Цена новая ₽',
              name: 'data-price-new-rub',
              type: 'text',
            },
            {
              label: 'Цена новая €',
              name: 'data-price-new-eur',
              type: 'text',
            },
            {
              label: 'Ссылка ₽',
              name: 'data-href-rub',
              type: 'text',
            },
            {
              label: 'Ссылка €',
              name: 'data-href-eur',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTariffsCourse
        }
      }

    })
  });


  // Define new Trait type - getcourse group
  const getcourseGroupType = 'getcourse-group';
  editor.TraitManager.addType(getcourseGroupType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(getcourseGroupType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[getcourseGroupType] = newVal;
    }
  });

  // Define new Trait type - getcourse redirect URL
  const getcourseRedirectURLType = 'getcourse-redirect-url';
  editor.TraitManager.addType(getcourseRedirectURLType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(getcourseRedirectURLType, newVal);
      // save new value of redirect url to attributes
      this.target.attributes.attributes[getcourseRedirectURLType] = newVal;
    }
  });

  const careerRequestComponents = [
    'careerum-club40-goals',
    'careerum-club40-goals-tablet',
    'careerum-club40-goals-mobile',
    'careerum-club40-form',
    'careerum-club40-form-tablet',
    'careerum-club40-form-mobile',
    'careerum-club40-popup-subscribe',
    'careerum-club40-popup-lk',
    'careerum-blackfriday-form',
    'careerum-gift',
    'careerum-veb4-main',
    'careerum-veb4-form',
    'careerum-product-form'
  ];
  let defaultAttributes: any = {
    required: false
  };
  defaultAttributes[getcourseGroupType] = '';
  defaultAttributes[getcourseRedirectURLType] = '';

  careerRequestComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Группа в GetCourse для лидов',
              name: getcourseGroupType,
              type: getcourseGroupType,
              changeProp: 1,
            },
            {
              label: 'Редирект после добавления в группу GetCourse',
              name: getcourseRedirectURLType,
              type: getcourseRedirectURLType,
              changeProp: 1,
            },
            {
              label: 'Попап после добавления в группу (id)',
              name: 'data-popup-open',
              type: 'text',
            }
          ],
          attributes: defaultAttributes
        }
      }

    })
  });

  // Define new Trait type - timer
  const timerType = 'data-timer';
  editor.TraitManager.addType(timerType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(timerType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[timerType] = newVal;
    }
  });

  const timerComponents = [
		'careerum-course40-banner1',
    'careerum-course40-banner1-tablet',
    'careerum-course40-banner1-mobile',
    'careerum-blackfriday-banner',
    'careerum-club40-banner-top'
	];
  let defaultAttributesTimer: any = { 
    required: false
  };
  defaultAttributesTimer[timerType] = '';

  timerComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Статичная дата',
              name: 'data-static',
              type: 'checkbox',
              valueTrue: 'yes', 
              valueFalse: 'no',
            },
            {
              label: 'Дата и время окончания отсчета (08/16/2022 10:00 GMT+0300)',
              name: 'data-timer-end',
              type: 'text',
            },
            {
              label: 'Кол-во часов (для нестатичной даты)',
              name: 'data-offset',
              type: 'text',
            },
            {
              label: 'Редирект по окончании',
              name: 'data-redirect',
              type: 'checkbox',
              valueTrue: 'yes', 
              valueFalse: 'no',
            },
            {
              label: 'URL редиректа',
              name: 'data-url',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTimer
        }
      }
  
    })
  });

  // Define new Trait type - club video ID
  const videoType = 'data-video-id';
  editor.TraitManager.addType(videoType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(videoType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[videoType] = newVal;
    }
  });

  const videoComponents = [
    'careerum-club40-main2',
  ];
  let defaultAttributesVideo: any = {
    required: false
  };
  defaultAttributesVideo[videoType] = '';

  videoComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Vimeo Video ID',
              name: videoType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesVideo
        }
      }

    })
  });


  // Define new Trait type - Black friday tales settings
  const tariffsBFCourseType = 'data-price';
  editor.TraitManager.addType(tariffsBFCourseType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(tariffsBFCourseType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[tariffsBFCourseType] = newVal;
    }
  });

  const tariffsBFCourseComponents = [
    'blackfriday-tale-change-currency'
  ];
  let defaultAttributesTariffsBFCourse: any = {
    required: false
  };
  defaultAttributesTariffsBFCourse[tariffsBFCourseType] = '';

  tariffsBFCourseComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена ₽',
              name: 'data-price-rub',
              type: 'text',
            },
            {
              label: 'Цена €',
              name: 'data-price-eur',
              type: 'text',
            },
            {
              label: 'Цена новая ₽',
              name: 'data-price-new-rub',
              type: 'text',
            },
            {
              label: 'Цена новая €',
              name: 'data-price-new-eur',
              type: 'text',
            },
            {
              label: 'Ссылка ₽',
              name: 'data-href-rub',
              type: 'text',
            },
            {
              label: 'Ссылка €',
              name: 'data-href-eur',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTariffsBFCourse
        }
      }

    })
  });
  // Define new Trait type - data href for banner
  const hrefdataType = 'data-href';
  editor.TraitManager.addType(hrefdataType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(hrefdataType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[hrefdataType] = newVal;
    }
  });

  const hrefdataComponents = [
		'careerum-club40-banner-top'
	];
  let defaultAttributesHrefData: any = { 
    required: false
  };
  defaultAttributesHrefData[hrefdataType] = '';

  hrefdataComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Якорь на блок',
              name: hrefdataType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesHrefData
        }
      }
  
    })
  });

});
