export default function(editor: any, opt: any = {}) {
	const domc = editor.DomComponents;
    let componentName: string;
    let componentClass: string;
    let droppableWrapperSelector = '[data-gjs-type="wrapper"]';
    const traits = { 
        traits: [
            {
                name: 'id'
            },
            {
                label: 'Не обновлять',
                name: 'universal-block-not-update',
                type: 'checkbox'
            }
        ]
    };

    // universal and self-updatable components as header, footer and tariff block

    componentName = 'careerum-header-2';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.20",
                },
                components: `
                <div>
                    <div class="careerum-header__logo">
                        <a href="/ru-ru">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857387586.svg" alt="">
                        </a>
                    </div>
                    <div class="careerum-header__menu">
                        <a href="/ru-ru/club" class="careerum-header__club">Careerum.Club</a>
                        <a href="/ru-ru/club/gift-certificate" class="careerum-header__coaching">Подарочные сертификаты</a>
                        <a href="/ru-ru/expert-consultations" class="careerum-header__consultations">Консультации</a>
                        <a href="/ru-ru/success-stories" class="careerum-header__stories">Результаты участников</a>
                        <a href="/ru-ru/team" class="careerum-header__team">О нас</a>
                        <a href="#" class="careerum-header__blog hidden">Блог</a>
                        <a href="#" class="careerum-header__faq hidden">FAQ</a>
                        <a href="#" class="careerum-header__vacancies hidden">Вакансии</a>
                    </div>
                    <div class="careerum-header__dropdown">
                        <div>
                            <input type="checkbox" id="careerum-enter-input">
                            <label for="careerum-enter-input">Войти</label>
                            <div>
                                <a href="https://learn.careerum.com/">Образовательная платформа</a>
                                <a href="https://network.careerum.ru/">Платформа для нетворкинга</a>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-header-2-tablet';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.20",
                },
                components: `
                <div>
                    <div>
                        <input type="checkbox" name="" id="careerum-header-check-tablet">
                        <label for="careerum-header-check-tablet"></label>
                        <div>
                            <div>
                                <a href="/ru-ru/club">Careerum.Club</a>
                                <a href="/ru-ru/club/gift-certificate">Подарочные сертификаты</a>
                                <a href="/ru-ru/expert-consultations">Консультации</a>
                                <a href="/ru-ru/success-stories">Результаты участников</a>
                                <a href="/ru-ru/team" class="careerum-header__team">О нас</a>
                                <a href="#" class="hidden">Блог</a>
                                <a href="#" class="hidden">FAQ</a>
                                <a href="#" class="hidden">Вакансии</a>
                            </div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857430505.svg" alt="">
                            </div>
                            <div>
                                <a href="https://learn.careerum.com/">Войти на образовательную<br> платформу</a>
                                <a href="https://network.careerum.ru/">Войти на платформу<br> для нетворкинга</a>
                            </div>
                        </div>
                    </div>
                    <div class="careerum-header__logo">
                        <a href="/ru-ru">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857454319.svg" alt="">
                        </a>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-header-2-mobile';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.20",
                },
                components: `
                <div>
                    <div style="">
                        <input type="checkbox" name="" id="careerum-header-check-mobile">
                        <label for="careerum-header-check-mobile"></label>
                        <div>
                            <div>
                                <a href="/ru-ru/club">Careerum.Club</a>
                                <a href="/ru-ru/club/gift-certificate">Подарочные сертификаты</a>
                                <a href="/ru-ru/expert-consultations">Консультации</a>
                                <a href="/ru-ru/success-stories">Результаты участников</a>
                                <a href="/ru-ru/team">О нас</a>
                                <a href="#" class="hidden">Блог</a>
                                <a href="#" class="hidden">FAQ</a>
                                <a href="#" class="hidden">Вакансии</a>
                            </div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857477377.svg" alt="">
                            </div>
                            <div>
                                <a href="https://learn.careerum.com/">Войти на образовательную<br> платформу</a>
                                <a href="https://network.careerum.ru/">Войти на платформу<br> для нетворкинга</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href="/ru-ru">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654857454319.svg" alt="">
                        </a>
                    </div>
                </div>
                `,
            }
        }
    });


    
    componentName = 'careerum-footer-2';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.43",
                },
                components: `
                <div>
                    <div>
                        <div><a onclick="" href="/"><img
                                    src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860177254.svg"
                                    alt=""></a></div>
                        <div>
                            <div><a onclick="" href="/ru-ru/club/gift-certificate">
                                    <p>Подарочные сертификаты</p>
                                </a><a onclick="" href="/ru-ru/expert-consultations">
                                    <p>Карьерные консультации</p>
                                </a><a onclick="" href="/ru-ru/coaching">
                                    <p>Консультации с Ольгой Лермонтовой</p>
                                </a><a onclick="" href="/ru-ru/tips">
                                    <p>Careerum.Tips</p>
                                </a><a onclick="" href="/ru-ru/club/b2b-offer">
                                    <p>Корпоративное обучение</p>
                                </a><a onclick="" href="#" class="hidden">
                                    <p>Индивидуальный коучинг</p>
                                </a></div>
                            <div><a onclick="" href="/ru-ru/team">
                                    <p>О нас</p>
                                </a><a onclick="" href="/ru-ru/team#vacancies">
                                    <p>Вакансии</p>
                                </a><a onclick="" href="#" class="hidden">
                                    <p>Блог</p>
                                </a></div>
                            <div><a onclick="" href="/ru-intl/legal/offer" target="_blank">
                                    <p>Публичная оферта</p>
                                </a><a onclick="" href="/ru-ru/legal/privacy" target="_blank">
                                    <p>Политика конфиденциальности</p>
                                </a><a onclick="" href="/ru-ru/legal/refund" target="_blank">
                                    <p>Политика возвратов</p>
                                </a></div>
                        </div>
                        <div>
                            <p>© Careerum 2025<br draggable="true" data-highlightable="1">
                                Все права защищены</p>
                            <p>Careerum OÜ. Reg. No 14726350<br>
                                Адрес: Vesivärava 50-201, Tallinn 10152, Estonia.</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>Мы в соцсетях</p>
                            <div><a onclick="" href="https://www.instagram.com/careerum.platform/" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860242858.svg"
                                        alt=""></a><a onclick="" href="https://www.facebook.com/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860267711.svg"
                                        alt=""></a><a onclick="" href="https://www.linkedin.com/company/careerum/"
                                    target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860292550.svg"
                                        alt=""></a><a onclick="" href="https://www.youtube.com/c/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860308211.svg"
                                        alt=""></a><a onclick="" href="https://t.me/dreamcatchme" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860321012.svg"
                                        alt=""></a></div>
                        </div>
                        <div>
                            <p>Связаться с нами</p>
                            <div><a onclick="" href="mailto:hello@careerum.com"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860338378.svg"
                                        alt=""></a><a onclick="" href="https://school.careerum.ru/tlgrm" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860350470.svg"
                                        alt=""></a><a onclick="" href="https://m.me/careerum" target="_blank"
                                    class="hidden"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860361137.svg"
                                        alt=""></a><a onclick="" href="https://api.whatsapp.com/send?phone=79958807862"
                                    target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860373852.svg"
                                        alt=""></a></div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-footer-2-tablet';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.43",
                },
                components: `
                <div>
                    <div>
                        <a onclick="" href="/"><img
                                src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860177254.svg" alt=""></a>
                    </div>
                    <div>
                        <div><a onclick="" href="/ru-ru/club/gift-certificate">
                                <p>Подарочные сертификаты</p>
                            </a><a onclick="" href="/ru-ru/expert-consultations">
                                <p>Карьерные консультации</p>
                            </a><a onclick="" href="/ru-ru/coaching">
                                <p>Консультации с Ольгой Лермонтовой</p>
                            </a><a onclick="" href="/ru-ru/tips">
                                <p>Careerum.Tips</p>
                            </a><a onclick="" href="/ru-ru/club/b2b-offer" target="_blank">
                                <p>Корпоративное обучение</p>
                            </a><a onclick="" href="#" class="hidden">
                                <p>Индивидуальный коучинг</p>
                            </a></div>
                        <div><a onclick="" href="/ru-ru/team">
                                <p>О нас</p>
                            </a><a onclick="" href="/ru-ru/team#vacancies">
                                <p>Вакансии</p>
                            </a><a onclick="" href="#" class="hidden">
                                <p>Блог</p>
                            </a></div>
                        <div><a onclick="" href="/ru-intl/legal/offer" target="_blank">
                                <p>Публичная оферта</p>
                            </a><a onclick="" href="/ru-ru/legal/privacy" target="_blank">
                                <p>Политика конфиденциальности</p>
                            </a><a onclick="" href="/ru-ru/legal/refund" target="_blank">
                                <p>Политика возвратов</p>
                            </a></div>
                    </div>
                    <div>
                        <div>
                            <p>Мы в соцсетях</p>
                            <div><a onclick="" href="https://www.instagram.com/careerum.platform/" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860533550.svg"
                                        alt=""></a><a onclick="" href="https://www.facebook.com/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860550549.svg"
                                        alt=""></a><a onclick="" href="https://www.linkedin.com/company/careerum/"
                                    target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860569043.svg"
                                        alt=""></a><a onclick="" href="https://www.youtube.com/c/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860590673.svg"
                                        alt=""></a><a onclick="" href="https://t.me/dreamcatchme" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860607882.svg"
                                        alt=""></a></div>
                        </div>
                        <div>
                            <p>Связаться с нами</p>
                            <div><a onclick="" href="mailto:hello@careerum.com"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860627796.svg"
                                        alt=""></a><a onclick="" href="https://school.careerum.ru/tlgrm" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860607882.svg"
                                        alt=""></a><a onclick="" href="https://m.me/careerum" target="_blank"
                                    class="hidden"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860644279.svg"
                                        alt=""></a><a onclick="" href="https://api.whatsapp.com/send?phone=79958807862"
                                    target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860660075.svg"
                                        alt=""></a></div>
                        </div>
                    </div>
                    <div>
                        <p>© Careerum 2025<br draggable="true" data-highlightable="1">
                            Все права защищены</p>
                        <p>Careerum OÜ. Reg. No 14726350<br>
                            Адрес: Vesivärava 50-201, Tallinn 10152, Estonia.</p>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-footer-2-mobile';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.43",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <a onclick="" href="/ru-ru/club/gift-certificate">
                                <p>Подарочные сертификаты</p>
                            </a>
                            <a onclick="" href="/ru-ru/expert-consultations">
                                <p>Карьерные консультации</p>
                            </a>
                            <a onclick="" href="/ru-ru/coaching">
                                <p>Консультации с Ольгой Лермонтовой</p>
                            </a>
                            <a onclick="" href="/ru-ru/tips">
                                <p>Careerum.Tips</p>
                            </a>
                            <a onclick="" href="#" class="hidden">
                                <p>Индивидуальный коучинг</p>
                            </a>
                            <a onclick="" href="/ru-ru/club/b2b-offer" target="_blank">
                                <p>Корпоративное обучение</p>
                            </a>
                        </div>
                        <div>
                            <a onclick="" href="/ru-ru/team">
                                <p>О нас</p>
                            </a>
                            <a onclick="" href="/ru-ru/team#vacancies">
                                <p>Вакансии</p>
                            </a>
                            <a onclick="" href="#" class="hidden">
                                <p>Блог</p>
                            </a>
                        </div>
                        <div>
                            <a onclick="" href="/ru-intl/legal/offer" target="_blank">
                                <p>Публичная оферта</p>
                            </a>
                            <a onclick="" href="/ru-ru/legal/privacy" target="_blank">
                                <p>Политика конфиденциальности</p>
                            </a>
                            <a onclick="" href="/ru-ru/legal/refund" target="_blank">
                                <p>Политика возвратов</p>
                            </a>
                        </div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1655565814347.svg" alt="">
                    </div>
                    <div>
                        <div>
                            <p>Мы в соцсетях</p>
                            <div><a onclick="" href="https://www.instagram.com/careerum.platform/" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860533550.svg"
                                        alt=""></a><a onclick="" href="https://www.facebook.com/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860550549.svg"
                                        alt=""></a><a onclick="" href="https://www.linkedin.com/company/careerum/"
                                    target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860569043.svg"
                                        alt=""></a><a onclick="" href="https://www.youtube.com/c/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860590673.svg"
                                        alt=""></a><a onclick="" href="https://t.me/dreamcatchme" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860607882.svg"
                                        alt=""></a></div>
                        </div>
                        <div>
                            <p>Связаться с нами</p>
                            <div><a onclick="" href="mailto:hello@careerum.com"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860627796.svg"
                                        alt=""></a><a onclick="" href="https://school.careerum.ru/tlgrm" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860607882.svg"
                                        alt=""></a><a onclick="" href="https://m.me/careerum" target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860644279.svg"
                                        alt=""></a><a onclick="" href="https://api.whatsapp.com/send?phone=79958807862"
                                    target="_blank"><img
                                        src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1654860660075.svg"
                                        alt=""></a></div>
                        </div>
                    </div>
                    <div>
                        <p>© Careerum 2025 Все права защищены</p>
                        <p>Careerum OÜ. Reg. No 14726350<br>
                            Адрес: Vesivärava 50-201, Tallinn 10152, Estonia.</p>
                    </div>
                </div>
                `,
            }
        }
    });



    
    componentName = 'careerum-new-tariffs-eng';
    componentClass = 'careerum-new-tariffs';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentClass) && el.getAttribute("hide-by-geo") === "russia",
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentClass,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.49",
                    "hide-by-geo": "russia",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <h2>Развивайтесь <br>в карьере с<br> Careerum.Club</h2>
                        </div>
                        <div>
                            <div>
                                <p class="hidden">Клуб откроется 11 февраля 2023</p>
                            </div>
                            <div class="careerum-new-geochanger">
                                <div>
                                    <div><label class="geo-new-label">
                                            <p>EUR</p><input type="checkbox" wfd-id="id5">
                                            <div class="geo-new-changer">
                                                <p data-currency="Russia" class="geo-selector">RUB</p>
                                            </div>
                                        </label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>Тариф</p>
                            <h3>Самостоятельный</h3>
                            <ul>
                                <li class="active">Обучение</li>
                                <li class="active">Нетворкинг</li>
                                <li class="active">Поддержка сообщества</li>
                                <li class="unactive">Поддержка коуча</li>
                                <li class="unactive">Помощь эксперта по резюме</li>
                                <li class="unactive">Личные сессии с коучем</li>
                            </ul>
                            <div>
                                <p>149 €</p>
                                <p>в месяц</p>
                            </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                        </div>
                        <div class="best-offer">
                            <div class="label-places-left"><span>Популярный</span></div>
                            <p>Тариф</p>
                            <h3>С поддержкой</h3>
                            <ul>
                                <li class="active">Обучение</li>
                                <li class="active">Нетворкинг</li>
                                <li class="active">Поддержка сообщества</li>
                                <li class="active">Поддержка коуча</li>
                                <li class="active">Помощь эксперта по резюме</li>
                                <li class="unactive">Личные сессии с коучем</li>
                            </ul>
                            <div>
                                <p>199 €</p>
                                <p>в месяц</p>
                            </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                        </div>
                        <div>
                            <p>Тариф</p>
                            <h3>Полное сопровождение</h3>
                            <ul>
                                <li class="active">Обучение</li>
                                <li class="active">Нетворкинг</li>
                                <li class="active">Поддержка сообщества</li>
                                <li class="active">Поддержка коуча</li>
                                <li class="active">Помощь эксперта по резюме</li>
                                <li class="active">Две личные сессии с коучем</li>
                            </ul>
                            <div>
                                <p>349 €</p>
                                <p>в месяц</p>
                            </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                        </div>
                    </div>
                </div>
        `,
            }
        }
    });

    componentName = 'careerum-new-tariffs-rus';
    componentClass = 'careerum-new-tariffs';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentClass) && el.getAttribute("hide-by-geo") === "not_russia",
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentClass,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.49",
                    "hide-by-geo": "not_russia",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <h2>Развивайтесь <br>в карьере с<br> Careerum.Club</h2>
                        </div>
                        <div>
                            <div>
                                <p class="hidden">Клуб откроется 11 февраля 2023</p>
                            </div>
                            <div class="careerum-new-geochanger">
                                <div>
                                    <div><label class="geo-new-label">
                                            <p>RUB</p><input type="checkbox" wfd-id="id6">
                                            <div class="geo-new-changer">
                                                <p data-currency="Other" class="geo-selector">EUR</p>
                                            </div>
                                        </label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>Тариф</p>
                            <h3>Самостоятельный</h3>
                            <ul>
                                <li class="active">Обучение</li>
                                <li class="active">Нетворкинг</li>
                                <li class="active">Поддержка сообщества</li>
                                <li class="unactive">Поддержка коуча</li>
                                <li class="unactive">Помощь эксперта по резюме</li>
                                <li class="unactive">Личные сессии с коучем</li>
                            </ul>
                            <div>
                                <p>8 900 ₽</p>
                                <p>в месяц</p>
                            </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                        </div>
                        <div class="best-offer">
                            <div class="label-places-left"><span>Популярный</span></div>
                            <p>Тариф</p>
                            <h3>С поддержкой</h3>
                            <ul>
                                <li class="active">Обучение</li>
                                <li class="active">Нетворкинг</li>
                                <li class="active">Поддержка сообщества</li>
                                <li class="active">Поддержка коуча</li>
                                <li class="active">Помощь эксперта по резюме</li>
                                <li class="unactive">Личные сессии с коучем</li>
                            </ul>
                            <div>
                                <p>11 900 ₽</p>
                                <p>в месяц</p>
                            </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                        </div>
                        <div>
                            <p>Тариф</p>
                            <h3>Полное сопровождение</h3>
                            <ul>
                                <li class="active">Обучение</li>
                                <li class="active">Нетворкинг</li>
                                <li class="active">Поддержка сообщества</li>
                                <li class="active">Поддержка коуча</li>
                                <li class="active">Помощь эксперта по резюме</li>
                                <li class="active">Две личные сессии с коучем</li>
                            </ul>
                            <div>
                                <p>20 900 ₽</p>
                                <p>в месяц</p>
                            </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-new-tariffs-tablet-eng';
    componentClass = 'careerum-new-tariffs-tablet';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentClass) && el.getAttribute("hide-by-geo") === "russia",
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentClass,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.49",
                    "hide-by-geo": "russia",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <h2>Развивайтесь <br>в карьере с<br> Careerum.Club</h2>
                        </div>
                        <div>
                            <div>
                                <p class="hidden">Клуб откроется 11 февраля 2023</p>
                            </div>
                            <div class="careerum-new-geochanger">
                                <div>
                                    <div><label class="geo-new-label">
                                            <p>EUR</p><input type="checkbox">
                                            <div class="geo-new-changer">
                                                <p data-currency="Russia" class="geo-selector">RUB</p>
                                            </div>
                                        </label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <p>Тариф</p>
                                <h3>Самостоятельный</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="unactive">Поддержка коуча</li>
                                    <li class="unactive">Помощь эксперта по резюме</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>149 €</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div class="best-offer">
                                <div class="label-places-left"><span>Популярный</span></div>
                                <p>Тариф</p>
                                <h3>С поддержкой</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь эксперта по резюме</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>199 €</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div>
                                <p>Тариф</p>
                                <h3>Полное сопровождение</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь эксперта по резюме</li>
                                    <li class="active">Две личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>349 €</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-new-tariffs-tablet-rus';
    componentClass = 'careerum-new-tariffs-tablet';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentClass) && el.getAttribute("hide-by-geo") === "not_russia",
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentClass,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.49",
                    "hide-by-geo": "not_russia",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <h2>Развивайтесь <br>в карьере с<br> Careerum.Club</h2>
                        </div>
                        <div>
                            <div>
                                <p class="hidden">Клуб откроется 11 февраля 2023</p>
                            </div>
                            <div class="careerum-new-geochanger">
                                <div>
                                    <div><label class="geo-new-label">
                                            <p>RUB</p><input type="checkbox" wfd-id="id22">
                                            <div class="geo-new-changer">
                                                <p data-currency="Other" class="geo-selector">EUR</p>
                                            </div>
                                        </label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <p>Тариф</p>
                                <h3>Самостоятельный</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="unactive">Поддержка коуча</li>
                                    <li class="unactive">Помощь эксперта по резюме</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>8 900 ₽</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div class="best-offer">
                                <div class="label-places-left"><span>Популярный</span></div>
                                <p>Тариф</p>
                                <h3>С поддержкой</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь эксперта по резюме</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>11 900 ₽</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div>
                                <p>Тариф</p>
                                <h3>Полное сопровождение</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь эксперта по резюме</li>
                                    <li class="active">Две личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>20 900 ₽</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-new-tariffs-mobile-eng';
    componentClass = 'careerum-new-tariffs-mobile';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentClass) && el.getAttribute("hide-by-geo") === "russia",
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentClass,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.49",
                    "hide-by-geo": "russia",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <h2>
                                Развивайтесь <br>в карьере с<br>
                                Careerum.Club
                            </h2>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <p>Тариф</p>
                                <h3>Самостоятельный</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="unactive">Поддержка коуча</li>
                                    <li class="unactive">Помощь CV-эксперта</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>149 €</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div class="best-offer">
                                <div class="label-places-left"><span>Популярный</span></div>
                                <p>Тариф</p>
                                <h3>С поддержкой</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь CV-эксперта</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>199 €</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div>
                                <p>Тариф</p>
                                <h3>Полное сопровождение</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь CV-эксперта</li>
                                    <li class="active">Две сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>349 €</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="hidden">Клуб откроется 11 февраля 2023</p>
                        <div class="careerum-new-geochanger">
                            <div>
                                <div>
                                    <label class="geo-new-label">
                                        <p>EUR</p><input type="checkbox" wfd-id="id33">
                                        <div class="geo-new-changer">
                                            <p data-currency="Russia" class="geo-selector">
                                                RUB
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });

    componentName = 'careerum-new-tariffs-mobile-rus';
    componentClass = 'careerum-new-tariffs-mobile';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentClass) && el.getAttribute("hide-by-geo") === "not_russia",
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentClass,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.49",
                    "hide-by-geo": "not_russia",
                },
                components: `
                <div>
                    <div>
                        <div>
                            <h2>Развивайтесь <br>в карьере с<br> Careerum.Club</h2>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <p>Тариф</p>
                                <h3>Самостоятельный</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="unactive">Поддержка коуча</li>
                                    <li class="unactive">Помощь CV-эксперта</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>8 900 ₽</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div class="best-offer">
                                <div class="label-places-left"><span>Популярный</span></div>
                                <p>Тариф</p>
                                <h3>С поддержкой</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь CV-эксперта</li>
                                    <li class="unactive">Личные сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>11 900 ₽</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                            <div>
                                <p>Тариф</p>
                                <h3>Полное сопровождение</h3>
                                <ul>
                                    <li class="active">Обучение</li>
                                    <li class="active">Нетворкинг</li>
                                    <li class="active">Поддержка сообщества</li>
                                    <li class="active">Поддержка коуча</li>
                                    <li class="active">Помощь CV-эксперта</li>
                                    <li class="active">Две сессии с коучем</li>
                                </ul>
                                <div>
                                    <p>20 900 ₽</p>
                                    <p>в месяц</p>
                                </div><a href="/ru-ru/club/waiting-list">Забронировать место</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="hidden">Клуб откроется 11 февраля 2023</p>
                        <div class="careerum-new-geochanger">
                            <div>
                                <div>
                                    <label class="geo-new-label">
                                        <p>RUB</p><input type="checkbox" wfd-id="id34">
                                        <div class="geo-new-changer">
                                            <p data-currency="Other" class="geo-selector">EUR</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });



    /* VERTICAL TABS */
    componentName = 'careerum-tabs-vertical';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.00",
                },
                components: `
            <div>
                <div>
                    <h2><span>350+ часов</span> курсов и мастер-классов,<br> которые помогут решить любую<br> карьерную задачу</h2>
                </div>
                <div>
                    <div>
                        <form action="">
                            <label class="selected"><p>Найти работу</p><input type="radio" name="tabs-v" id="" checked></label>
                            <label><p>Получить повышение</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Зарабатывать больше</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Переехать по работе</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Выйти из декрета</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Сменить сферу</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Понять, куда двигаться</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Укрепить самооценку</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Полюбить свою работу</p><input type="radio" name="tabs-v" id=""></label>
                            <label><p>Расти в кризис</p><input type="radio" name="tabs-v" id=""></label>
                        </form>
                    </div>
                    <div>
                        <div data-tile-name="Найти работу" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Умная стратегия поиска<br> работы</h3>
                                            <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках,
                                                которое точно рассмотрят</p>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>46 мин.</p>
                                        </div>
                                        <div>
                                            <h3>Hooked: как написать цепляющее сопрово-дительное письмо</h3>
                                            <p>Научитесь писать сопроводительное письмо, которое поможет выделиться среди других соискателей
                                                <br>и получить оффер</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>2 часа 21 мин.</p>
                                        </div>
                                        <h3>Как попасть<br> в Google: секреты<br> и подводные камни</h3>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 33 мин.</p>
                                        </div>
                                        <h3>Эффективное интервью</h3>
                                        <p>Поймете, как выгодно подать себя на интервью, и научитесь отвечать даже на самые сложные вопросы
                                            рекрутера</p>
                                    </div>
                                </div>
                            </div>
                            <div class="careerum-flex-row">
                                <div class="careerum-vebinar-tab-item">
                                    <div>
                                        <p>Вебинар</p>
                                        <p>1 час 37 мин.</p>
                                    </div>
                                    <h3>The other side:<br> recruiter’s view<br> on a job search</h3>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/find-job" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Получить повышение" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>27 мин.</p>
                                        </div>
                                        <div>
                                            <h3>Менталитет продавца</h3>
                                            <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                        </div>
                                    </div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 16 мин.</p>
                                            </div>
                                            <h3>Карьера <br>как проект</h3>
                                            <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>6 часов 9 мин.</p>
                                            </div>
                                            <h3>Первые месяцы <br>на новой работе</h3>
                                            <p>Получите пошаговый план, чтобы легко адаптироваться <br>и влиться в команду</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>1 час 15 мин.</p>
                                        </div>
                                        <h3>Личная <br>эффективность</h3>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>8 часов 51 мин.</p>
                                        </div>
                                        <h3>Big Boss: <br>быть, а не казаться</h3>
                                        <p>Прокачаете лидерские качества и научитесь выстраивать работу команды</p>
                                    </div>
                                </div>
                            </div>
                            <div class="careerum-flex-row">
                                <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                    <div>
                                        <p>Сессия</p>
                                        <p>1 час</p>
                                    </div>
                                    <h3>Как забирать стратегически <br>важные проекты</h3>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>1 час 17 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Zoom out: мыслить как стратег</h3>
                                        <p>Узнаете, что такое helicopter view и как его развивать, чтобы выстраивать эффективные стратегии
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/get-promotion" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Зарабатывать больше" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>56 мин.</p>
                                            </div>
                                            <h3>Пробей свой зарплатный потолок</h3>
                                            <p>Научитесь экологично обсуждать повышение зарплаты с руководителем</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 50 мин.</p>
                                            </div>
                                            <h3>Сколько <br>я стою</h3>
                                            <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>8 часов 37 мин.</p>
                                        </div>
                                        <h3>Высокий <br>старт: как попасть в консалтинг</h3>
                                        <p>Узнаете, какие перспективы есть в консалтинге для разных специалистов&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell">
                                    <div class="row">
                                        <div class="cell">
                                            <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                                <div>
                                                    <p>Сессия</p>
                                                    <p>2 часа 40 мин.</p>
                                                </div>
                                                <h3>Анализ <br>потребностей рынка <br>и карьерных опций</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                        <div>
                                            <p>Сессия</p>
                                            <p>1 час 1 мин.</p>
                                        </div>
                                        <h3>Установки, которые мешают вам реализоваться</h3>
                                    </div>
                                </div>
                                <div class="cell">
                                    <div class="row">
                                        <div class="cell">
                                            <div class="careerum-master-tab-item">
                                                <div>
                                                    <p>Мастер-класс</p>
                                                    <p>27 минут</p>
                                                </div>
                                                <div>
                                                    <h3>Менталитет продавца</h3>
                                                    <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>2 часа 39 мин.</p>
                                        </div>
                                        <div>
                                            <h3>Great Deal: как получить оффер мечты</h3>
                                            <p>Изучите секреты ведения переговоров, чтобы получать желаемую зарплату и другие
                                                привлекательные условия</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="careerum-flex-row"></div>
                            <div><a href="/ru-ru/club/content/increase-your-salary" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Переехать по работе" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div class="careerum-flex-column">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Видеоурок</p>
                                            <p>43 мин.</p>
                                        </div>
                                        <h3>Как переехать <br>в Катар или ОАЭ</h3>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 35 мин.</p>
                                        </div>
                                        <h3>Работа <br>без границ</h3>
                                        <p>Составите пошаговый план поиска работы на международном рынке&nbsp;</p>
                                    </div>
                                </div>
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 14 мин.</p>
                                            </div>
                                            <h3>Connect: как использовать нетворкинг для жизни и карьеры</h3>
                                            <p>Поймете, как выстроить крепкий нетворк</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 31 мин.</p>
                                            </div>
                                            <h3>Пути профес-сиональной иммиграции <br>в США</h3>
                                            <p>Узнаете, как переехать <br>в США по работе</p>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>1 час 40 мин.</p>
                                        </div>
                                        <div>
                                            <h3>LinkedIn для развития карьеры</h3>
                                            <p>Узнаете, как использовать LinkedIn на 100% — для поиска работы и выстраивания нетворка</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="careerum-flex-row">
                                <div class="careerum-vebinar-tab-item">
                                    <div>
                                        <p>Вебинар</p>
                                        <p>1 час 41 мин.</p>
                                    </div>
                                    <h3>Communication with English-speaking colleagues</h3>
                                </div>
                                <div class="careerum-vebinar-tab-item">
                                    <div>
                                        <p>Видеоурок</p>
                                        <p>1 час 22 мин.</p>
                                    </div>
                                    <h3>Как переехать <br>в Швецию или Норвегию</h3>
                                </div>
                                <div class="careerum-vebinar-tab-item">
                                    <div>
                                        <p>Видеоурок</p>
                                        <p>51 мин.</p>
                                    </div>
                                    <h3>Как построить мультикультурную карьеру</h3>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/relocation" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Выйти из декрета" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Умная стратегия поиска<br> работы</h3>
                                            <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках,
                                                которое точно рассмотрят</p>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>27 мин.</p>
                                        </div>
                                        <div>
                                            <h3>Менталитет продавца</h3>
                                            <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="cell">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Вебинар</p>
                                                    <p>1 час 12 мин.</p>
                                                </div>
                                                <h3>Как совмещать <br>карьеру <br>и материнство</h3>
                                            </div>
                                        </div>
                                        <div class="cell">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Видеоурок</p>
                                                    <p>24 мин.</p>
                                                </div>
                                                <h3>Самопрезентация</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>5 часов 50 мин.</p>
                                        </div>
                                        <h3>Как успешно выйти <br>на работу <br>из декрета</h3>
                                        <p>Узнаете, как быстро вернуть профессиональную форму после долгого перерыва</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>11 часов 51 мин.</p>
                                        </div>
                                        <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                        <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="careerum-flex-row"></div>
                            <div><a href="/ru-ru/club/content/return-maternity-leave" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Сменить сферу" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 14 мин.</p>
                                            </div>
                                            <h3>Само-определение: профессионал в точке <br>выбора</h3>
                                            <p>Поймете свои истинные желания и определите цели</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках,
                                                которое точно рассмотрят</p>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>46 мин.</p>
                                        </div>
                                        <div>
                                            <h3>Hooked: как написать цепляющее сопрово-дительное письмо</h3>
                                            <p>Научитесь писать сопроводительное письмо, которое поможет выделиться среди других соискателей
                                                <br>и получить оффер</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>11 часов 51 мин.</p>
                                        </div>
                                        <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                        <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Видеоурок</p>
                                            <p>30 мин.</p>
                                        </div>
                                        <h3>Карьера в сфере искусственного интеллекта</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="careerum-flex-row">
                                <div class="careerum-vebinar-tab-item">
                                    <div>
                                        <p>Видеоурок</p>
                                        <p>27 мин.</p>
                                    </div>
                                    <h3>Карьера в дизайне</h3>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/career-change" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Понять, куда двигаться" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                        </div>
                                        <div class="careerum-flex-column">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Вебинар</p>
                                                    <p>1 час 34 мин.</p>
                                                </div>
                                                <h3>Как найти ментора <br>и построить с ним работу</h3>
                                            </div>
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Видеоурок</p>
                                                    <p>1 час 23 мин.</p>
                                                </div>
                                                <h3>Карьерный план: создать <br>и воплотить</h3>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 14 мин.</p>
                                            </div>
                                            <h3>Само-определение: профессионал в точке <br>выбора</h3>
                                            <p>Поймете свои истинные желания и определите цели</p>
                                        </div>
                                    </div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 16 мин.</p>
                                            </div>
                                            <h3>Карьера<br>как проект</h3>
                                            <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 50 мин.</p>
                                            </div>
                                            <h3>Сколько <br>я стою</h3>
                                            <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки
                                            </p>
                                        </div>
                                        <div class="careerum-flex-column">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Видеоурок</p>
                                                    <p>1 час 15 мин.</p>
                                                </div>
                                                <h3>Как сменить специализацию</h3>
                                            </div>
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Видеоурок</p>
                                                    <p>2 часа</p>
                                                </div>
                                                <h3>Введение <br>в продакт-<br>менеджмент</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/career-path" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Укрепить самооценку" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 50 мин.</p>
                                            </div>
                                            <h3>Сколько <br>я стою</h3>
                                            <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки
                                            </p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 27 мин.</p>
                                            </div>
                                            <h3>Как строить личные границы <br>на работе <br>и в жизни</h3>
                                            <p>Научитесь обозначать <br>и отстаивать границы</p>
                                        </div>
                                    </div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках,
                                                которое точно рассмотрят</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>2 часа 21 мин.</p>
                                        </div>
                                        <h3>Как попасть<br> в Google: секреты<br> и подводные камни</h3>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>2 часа 21 мин.</p>
                                        </div>
                                        <h3>Как попасть<br> в Google: секреты<br> и подводные камни</h3>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/increase-confidence-level" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Полюбить свою работу" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 42 мин.</p>
                                            </div>
                                            <h3>Баланс между работой<br>и личной жизнью</h3>
                                            <p>Договоритесь с внутренним критиком и найдете свой work-life balance</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="cell">
                                            <div class="careerum-master-tab-item">
                                                <div>
                                                    <p>Мастер-класс</p>
                                                    <p>27 мин.</p>
                                                </div>
                                                <div>
                                                    <h3>Менталитет продавца</h3>
                                                    <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>1 час 40 мин.</p>
                                        </div>
                                        <div>
                                            <h3>Нелинейная продуктивность</h3>
                                            <p>Научитесь достигать результатов в работе с заботой о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>1 час 34 мин.</p>
                                        </div>
                                        <h3>Как найти ментора <br>и построить с ним работу</h3>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Видеоурок</p>
                                            <p>2 часа</p>
                                        </div>
                                        <h3>Ваши мотиваторы</h3>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 16 мин.</p>
                                        </div>
                                        <h3>Карьера <br>как проект</h3>
                                        <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/love-your-job" target="_blank">Посмотреть все материалы</a></div>
                        </div>
                        <div data-tile-name="Расти в кризис" class="careerum-tab-wrapper">
                            <div class="careerum-flex-row">
                                <div>
                                    <div class="careerum-flex-row">
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках,
                                                которое точно рассмотрят</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 33 мин.</p>
                                            </div>
                                            <h3>Эффективное интервью</h3>
                                            <p>Поймете, как выгодно подать себя на интервью, и научитесь отвечать даже на самые сложные
                                                вопросы рекрутера</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="cell">
                                            <div class="careerum-master-tab-item">
                                                <div>
                                                    <p>Мастер-класс</p>
                                                    <p>46 мин.</p>
                                                </div>
                                                <div>
                                                    <h3>Hooked: как написать цепляющее сопрово-дительное письмо</h3>
                                                    <p>Научитесь писать сопроводительное письмо, которое поможет выделиться среди других
                                                        соискателей <br>и получить оффер</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="careerum-master-tab-item">
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>27 минут</p>
                                        </div>
                                        <div>
                                            <h3>Менталитет продавца</h3>
                                            <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-flex-column">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>1 час 32 мин.</p>
                                        </div>
                                        <h3>Карьера <br>и психология: тревожность</h3>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>1 час 34 мин.</p>
                                        </div>
                                        <h3>Финансы в кризис</h3>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Умная стратегия поиска <br>работы</h3>
                                        <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                    </div>
                                </div>
                            </div>
                            <div><a href="/ru-ru/club/content/growing-through-difficult-times" target="_blank">Посмотреть все материалы</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                `,
            }
        }
    });


    componentName = 'careerum-tabs-vertical-tablet';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.00",
                },
                components: `
                <div>
                    <div>
                        <h2><span>350+ часов</span> курсов и мастер-<br>классов, которые помогут<br> решить любую карьерную<br> задачу</h2>
                    </div>
                    <div>
                        <div>
                            <form action="">
                                <label class="selected"><p>Найти работу</p><input type="radio" name="tabs-v" id="" checked></label>
                                <label><p>Получить повышение</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Зарабатывать больше</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Переехать по работе</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Выйти из декрета</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Сменить сферу</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Понять, куда двигаться</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Укрепить самооценку</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Полюбить свою работу</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Расти в кризис</p><input type="radio" name="tabs-v" id=""></label>
                            </form>
                        </div>
                        <div>
                            <div data-tile-name="Найти работу" class="careerum-tab-wrapper">
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Умная стратегия поиска работы</h3>
                                        <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Продающее резюме</h3>
                                        <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках, которое
                                            точно рассмотрят</p>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>46 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Hooked: как написать цепляющее сопрово-дительное письмо</h3>
                                        <p>Научитесь писать сопроводительное письмо, которое выделит вас среди других соискателей</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 37 мин.</p>
                                            </div>
                                            <h3>The other side:<br> recruiter’s view<br> on a job search</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>2 часа 21 мин.</p>
                                            </div>
                                            <h3>Как попасть<br> в Google: секреты<br> и подводные камни</h3>
                                        </div>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 33 мин.</p>
                                        </div>
                                        <h3>Эффективное интервью</h3>
                                        <p>Поймете, как выгодно подать себя на интервью, и научитесь отвечать даже на самые сложные вопросы
                                            рекрутера</p>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет<br> продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/find-job" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Получить повышение" class="careerum-tab-wrapper">
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>1 час 17 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Zoom out: мыслить как стратег</h3>
                                        <p>Узнаете, что такое helicopter view и как его развивать, чтобы выстраивать эффективные стратегии</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 16 мин.</p>
                                        </div>
                                        <h3>Карьера <br>как проект</h3>
                                        <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>6 часов 9 мин.</p>
                                        </div>
                                        <h3>Первые <br>месяцы <br>на новой работе</h3>
                                        <p>Получите пошаговый план, чтобы легко адаптироваться <br>и влиться в команду</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>8 часов 51 мин.</p>
                                        </div>
                                        <h3>Big Boss: быть, а не казаться</h3>
                                        <p>Прокачаете лидерские качества и научитесь выстраивать работу команды</p>
                                    </div>
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 15 мин.</p>
                                            </div>
                                            <h3>Личная эффективность</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                            <div>
                                                <p>Сессия</p>
                                                <p>1 час 21 мин.</p>
                                            </div>
                                            <h3>Как продавать свои идеи руководителю</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет<br> продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/get-promotion" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Зарабатывать больше" class="careerum-tab-wrapper">
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>2 часа 39 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Great Deal: как получить оффер мечты</h3>
                                        <p>Изучите секреты ведения переговоров, чтобы получать желаемую зарплату и другие&nbsp; условия</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>56 мин.</p>
                                        </div>
                                        <h3>Пробей свой зарплатный потолок</h3>
                                        <p>Научитесь эклогично обсуждать повышение зарплаты с руководителем</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 50 мин.</p>
                                        </div>
                                        <h3>Сколько я <br>стою</h3>
                                        <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>8 часов 37 мин.</p>
                                        </div>
                                        <h3>Высокий <br>старт: как попасть в консалтинг</h3>
                                        <p>Узнаете, какие перспективы есть в консалтинге для <br>разных специалистов&nbsp;</p>
                                    </div>
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                            <div>
                                                <p>Сессия</p>
                                                <p>2 часа 40 мин.</p>
                                            </div>
                                            <h3>Анализ потребностей рынка и карьерных опций</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                            <div>
                                                <p>Сессия</p>
                                                <p>1 час 1 мин.</p>
                                            </div>
                                            <h3>Установки, которые мешают вам реализоваться</h3>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/increase-your-salary" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Переехать по работе" class="careerum-tab-wrapper">
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>1 час 40 мин.</p>
                                    </div>
                                    <div>
                                        <h3>LinkedIn для <br>развития карьеры</h3>
                                        <p>Узнаете, как использовать Linkedin на 100% — для поиска работы и выстраивания нетворка</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>1 час 22 мин.</p>
                                            </div>
                                            <h3>Как переехать <br>в Швецию или Норвегию</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>51 мин.</p>
                                            </div>
                                            <h3>Как построить мультикультурную карьеру</h3>
                                        </div>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 14 мин.</p>
                                        </div>
                                        <h3>Connect: как использовать нетворкинг <br>для жизни <br>и карьеры</h3>
                                        <p>Поймете, как выстроить крепкий нетворк</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 35 мин.</p>
                                        </div>
                                        <h3>Работа без границ</h3>
                                        <p>Составите пошаговый план поиска работы <br>на международном рынке&nbsp;</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 31 мин.</p>
                                        </div>
                                        <h3>Пути профес-сиональной иммиграции <br>в США</h3>
                                        <p>Узнаете, как переехать <br>в США по работе</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>43 мин.</p>
                                        </div>
                                        <h3>Как переехать <br>в Катар или ОАЭ</h3>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>1 час 41 мин.</p>
                                        </div>
                                        <h3>Communication with English-speaking colleagues</h3>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/relocation" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Выйти из декрета" class="careerum-tab-wrapper">
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Умная стратегия поиска работы</h3>
                                        <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Продающее резюме</h3>
                                        <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках, которое
                                            точно рассмотрят</p>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>11 часов 51 мин.</p>
                                        </div>
                                        <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                        <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 33 мин.</p>
                                        </div>
                                        <h3>Эффективное интервью</h3>
                                        <p>Поймете, как выгодно подать себя на интервью, и научитесь отвечать даже на самые сложные вопросы
                                            рекрутера</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 12 мин.</p>
                                            </div>
                                            <h3>Как совмещать карьеру <br>и материнство</h3>
                                        </div>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Вебинар</p>
                                            <p>2 часа 37 мин.</p>
                                        </div>
                                        <h3>Как попасть <br>в Google: секреты <br>и подводные камни</h3>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/return-maternity-leave" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Сменить сферу" class="careerum-tab-wrapper">
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 14 мин.</p>
                                        </div>
                                        <h3>Само-определение: профессионал в точке выбора</h3>
                                        <p>Поймете свои истинные желания и определите цели</p>
                                    </div>
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>30 мин.</p>
                                            </div>
                                            <h3>Карьера в сфере искусственного интеллекта</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>27 мин.</p>
                                            </div>
                                            <h3>Карьера в дизайне</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>46 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Hooked: как написать цепляющее сопрово-дительное письмо</h3>
                                        <p>Научитесь писать сопроводительное письмо, которое выделит вас среди других соискателей</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Продающее резюме</h3>
                                        <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках, которое
                                            точно рассмотрят</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>11 часов 51 мин.</p>
                                        </div>
                                        <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                        <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет<br> продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/career-change" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Понять, куда двигаться" class="careerum-tab-wrapper">
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>11 часов 51 мин.</p>
                                        </div>
                                        <h3>Real me: Как найти себя <br>в работе <br>и жизни</h3>
                                        <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 14 мин.</p>
                                        </div>
                                        <h3>Само-определение: профессионал в точке выбора</h3>
                                        <p>Поймете свои истинные желания и определите цели</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 34 мин.</p>
                                            </div>
                                            <h3>Как найти ментора <br>и построить с ним работу</h3>
                                        </div>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Видеоурок</p>
                                            <p>1 час 23 мин.</p>
                                        </div>
                                        <h3>Карьерный план: создать <br>и воплотить</h3>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 16 мин.</p>
                                        </div>
                                        <h3>Карьера <br>как проект</h3>
                                        <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 50 мин.</p>
                                        </div>
                                        <h3>Сколько я <br>стою</h3>
                                        <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>1 час 15 мин.</p>
                                            </div>
                                            <h3>Как сменить специализацию</h3>
                                        </div>
                                    </div>
                                    <div class="careerum-vebinar-tab-item">
                                        <div>
                                            <p>Видеоурок</p>
                                            <p>2 часа 17 мин.</p>
                                        </div>
                                        <h3>Введение в продакт-менеджмент</h3>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/career-path" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Укрепить самооценку" class="careerum-tab-wrapper">
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 50 мин.</p>
                                        </div>
                                        <h3>Сколько я <br>стою</h3>
                                        <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 27 мин.</p>
                                        </div>
                                        <h3>Как строить личные границы <br>на работе <br>и в жизни</h3>
                                        <p>Научитесь экологично обозначать и отстаивать границы</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="row">
                                        <div class="cell">
                                            <div class="careerum-course-tab-item">
                                                <div>
                                                    <p>Курс</p>
                                                    <p>11 часов 51 мин.</p>
                                                </div>
                                                <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                                <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                            </div>
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Видеоурок</p>
                                                    <p>1 час 37 мин.</p>
                                                </div>
                                                <h3>Перфекционист — наш главный враг</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>30 мин.</p>
                                            </div>
                                            <h3>Как найти в себе таланты</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках,
                                                которое точно рассмотрят</p>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/increase-confidence-level" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Полюбить свою работу" class="careerum-tab-wrapper">
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>11 часов 51 мин.</p>
                                        </div>
                                        <h3>Real me: как найти себя <br>в работе <br>и жизни</h3>
                                        <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться <br>на 100%</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 42 мин.</p>
                                        </div>
                                        <h3>Баланс между работой <br>и личной жизнью</h3>
                                        <p>Договоритесь с внутренним критиком и найдете свой <br>work-life balance</p>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>1 час 40 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Нелинейная продуктивность</h3>
                                        <p>Научитесь достигать результатов в работе с заботой о себе</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>1 час 16 мин.</p>
                                        </div>
                                        <h3>Карьера <br>как проект</h3>
                                        <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                    </div>
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 34 мин.</p>
                                            </div>
                                            <h3>Как найти ментора <br>и построить с ним работу</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>2 часа 17 мин.</p>
                                            </div>
                                            <h3>Ваши мотиваторы</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет<br> продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/love-your-job" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Расти в кризис" class="careerum-tab-wrapper">
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>46 мин.</p>
                                    </div>
                                    <div>
                                        <h3>Hooked: как написать цепляющее сопрово-дительное письмо</h3>
                                        <p>Научитесь писать сопроводительное письмо, которое выделит вас среди других соискателей</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Продающее резюме</h3>
                                        <p>Научитесь грамотно формулировать свои достижения и создадите сильное резюме на двух языках, которое
                                            точно рассмотрят</p>
                                    </div>
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 33 мин.</p>
                                        </div>
                                        <h3>Эффективное интервью</h3>
                                        <p>Поймете, как выгодно подать себя на интервью, и научитесь отвечать даже на самые сложные вопросы
                                            рекрутера</p>
                                    </div>
                                </div>
                                <div class="careerum-flex-row">
                                    <div class="careerum-course-tab-item">
                                        <div>
                                            <p>Курс</p>
                                            <p>2 часа 16 мин.</p>
                                        </div>
                                        <h3>Умная стратегия поиска работы</h3>
                                        <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                    </div>
                                    <div class="careerum-flex-column">
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 32 мин.</p>
                                            </div>
                                            <h3>Карьера <br>и психология: тревожность</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 34 мин.</p>
                                            </div>
                                            <h3>Финансы в кризис</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="careerum-master-tab-item">
                                    <div>
                                        <p>Мастер-класс</p>
                                        <p>27 минут</p>
                                    </div>
                                    <div>
                                        <h3>Менталитет<br> продавца</h3>
                                        <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/growing-through-difficult-times" target="_blank">Посмотреть все материалы</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });


    componentName = 'careerum-tabs-vertical-mobile';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.00",
                },
                components: `
                <div>
                    <div>
                        <h2><span>350+ часов</span> курсов<br> и мастер-классов<br> на карьерные темы</h2>
                    </div>
                    <div>
                        <div>
                            <form action="">
                                <label class="selected"><p>Найти работу</p><input type="radio" name="tabs-v" id="" checked></label>
                                <label><p>Получить повышение</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Зарабатывать больше</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Переехать по работе</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Выйти из декрета</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Сменить сферу</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Понять, куда двигаться</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Укрепить самооценку</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Полюбить свою работу</p><input type="radio" name="tabs-v" id=""></label>
                                <label><p>Расти в кризис</p><input type="radio" name="tabs-v" id=""></label>
                            </form>
                        </div>
                        <div>
                            <div data-tile-name="Найти работу" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Умная стратегия поиска работы</h3>
                                            <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>2 часа 21 мин.</p>
                                            </div>
                                            <h3>Как попасть в<br> Google: секреты<br> и подводные камни</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать достижения <br>и создадите сильное резюме, которое точно
                                                рассмотрят</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>46 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Hooked: как напи-сать цепляющее сопроводительное письмо</h3>
                                                <p>Научитесь писать письмо, которое поможет выделиться</p>
                                            </div>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 минут</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет<br> продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 33 мин.</p>
                                            </div>
                                            <h3>Эффективное интервью</h3>
                                            <p>Поймете, как выгодно подать себя на интервью и научитесь отвечать даже на самые сложные вопросы
                                                рекрутера</p>
                                        </div>
                                        <div class="careerum-flex-column">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Вебинар</p>
                                                    <p>1 час 37 мин.</p>
                                                </div>
                                                <h3>The other side:<br> recruiter’s view<br> on a job search</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/find-job" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Получить повышение" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 минут</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет<br> продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 15 мин.</p>
                                            </div>
                                            <h3>Личная эффективность</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 16 мин.</p>
                                            </div>
                                            <h3>Карьера <br>как проект</h3>
                                            <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>1 час 17 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Zoom out: мыслить как стратег</h3>
                                                <p>Узнаете, что такое helicopter view и как его развивать, чтобы выстраивать эффективные
                                                    стратегии</p>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>6 часов 9 мин.</p>
                                            </div>
                                            <h3>Первые месяцы <br>на новой работе</h3>
                                            <p>Получите пошаговый план,<br>&nbsp;чтобы легко адаптироваться и <br>влиться в команду</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>6 часов 9 мин.</p>
                                            </div>
                                            <h3>Первые месяцы <br>на новой работе</h3>
                                            <p>Получите пошаговый план,<br>&nbsp;чтобы легко адаптироваться и <br>влиться в команду</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>8 часов 51 мин.</p>
                                            </div>
                                            <h3>Big Boss: быть, <br>а не казаться</h3>
                                            <p>Прокачаете лидерские качества и научитесь выстраивать работу команды</p>
                                        </div>
                                        <div class="careerum-flex-column">
                                            <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                                <div>
                                                    <p>Сессия</p>
                                                    <p>1 час 21 мин.</p>
                                                </div>
                                                <h3>Как продавать свои идеи руководителю</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/get-promotion" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Зарабатывать больше" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>56 мин.</p>
                                            </div>
                                            <h3>Пробей свой зарплатный потолок</h3>
                                            <p>Научитесь эклогично обсуждать повышение зарплаты с руководителем</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 50 мин.</p>
                                            </div>
                                            <h3>Сколько я стою</h3>
                                            <p>Вместе с психологом поработаете над самооценкой, чтобы высоко оценивать собственные навыки</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                            <div>
                                                <p>Сессия</p>
                                                <p>2 часа 40 мин.</p>
                                            </div>
                                            <h3>Анализ потребностей рынка <br>и карьерных опций</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>8 часов 37 мин.</p>
                                            </div>
                                            <h3>Высокий старт: <br>как попасть <br>в консалтинг</h3>
                                            <p>Узнаете, какие перспективы есть в консалтинге для разных специалистов&nbsp;</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-vebinar-tab-item careerum-bg-very-light-blue">
                                            <div>
                                                <p>Сессия</p>
                                                <p>1 час 1 мин.</p>
                                            </div>
                                            <h3>Установки, которые мешают вам реализоваться</h3>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>2 часа 39 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Great Deal: как получить оффер мечты</h3>
                                                <p>Изучите секреты ведения переговоров, чтобы получать желаемую зарплату</p>
                                            </div>
                                        </div>
                                        <div class="careerum-flex-column"></div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/increase-your-salary" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Переехать по работе" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 35 мин.</p>
                                            </div>
                                            <h3>Работа без границ</h3>
                                            <p>Составите пошаговый план поиска работы на международном рынке&nbsp;</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>1 час 40 мин.</p>
                                            </div>
                                            <div>
                                                <h3>LinkedIn для развития карьеры</h3>
                                                <p>Узнаете, как использовать Linkedin на 100% — для поиска работы и выстраивания нетворка</p>
                                            </div>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 41 мин.</p>
                                            </div>
                                            <h3>Communication with English-speaking colleagues</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 25 мин.</p>
                                            </div>
                                            <h3>Language of a Job Interview</h3>
                                            <p>Learn job interview lifehacks and become confident with a small-talk and common questions</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>43 мин.</p>
                                            </div>
                                            <h3>Как переехать <br>в Катар или ОАЭ</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 22 мин.</p>
                                            </div>
                                            <h3>Как переехать <br>в Швецию или Норвегию</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 31 мин.</p>
                                            </div>
                                            <h3>Пути профес-сиональной иммиграции в США</h3>
                                            <p>Узнаете, как переехать <br>в США по работе</p>
                                        </div>
                                        <div class="careerum-flex-column">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Вебинар</p>
                                                    <p>51 мин.</p>
                                                </div>
                                                <h3>Как построить мультикуль-турную карьеру</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/relocation" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Выйти из декрета" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>5 часов 50 мин.</p>
                                            </div>
                                            <h3>Как успешно выйти на работу <br>из декрета</h3>
                                            <p>Узнаете, как быстро вернуть профессиональную форму после долгого перерыва</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 12 мин.</p>
                                            </div>
                                            <h3>Как совмещать карьеру <br>и материнство</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать достижения <br>и создадите сильное резюме, которое точно
                                                рассмотрят</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>24 мин.</p>
                                            </div>
                                            <h3>Самопрезентация</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Умная стратегия поиска работы</h3>
                                            <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 минут</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет<br> продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться</p>
                                        </div>
                                        <div class="careerum-flex-column"></div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/return-maternity-leave" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Сменить сферу" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 14 мин.</p>
                                            </div>
                                            <h3>Самоопределение: профессионал <br>в точке выбора</h3>
                                            <p>Поймете свои истинные желания и определите цели</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно <br>формулировать достижения <br>и создадите сильное резюме, которое точно
                                                рассмотрят</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>46 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Hooked: как напи-сать цепляющее сопроводительное письмо</h3>
                                                <p>Научитесь писать письмо, которое поможет выделиться</p>
                                            </div>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>30 мин.</p>
                                            </div>
                                            <h3>Карьера в сфере искусственного интеллекта</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>27 мин.</p>
                                            </div>
                                            <h3>Карьера в дизайне</h3>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 минут</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет<br> продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-flex-column"></div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/career-change" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Понять, куда двигаться" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 37 мин.</p>
                                            </div>
                                            <h3>The other side: recruiter’s view <br>on a job search</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>1 час 23 мин.</p>
                                            </div>
                                            <h3>Карьерный план: создать <br>и воплотить</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 14 мин.</p>
                                            </div>
                                            <h3>Самоопределение: профессионал <br>в точке выбора</h3>
                                            <p>Поймете свои истинные желания и определите цели</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 15 мин.</p>
                                            </div>
                                            <h3>Как сменить специализацию</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>2 часа 17 мин.</p>
                                            </div>
                                            <h3>Введение <br>в продакт-менеджмент</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 16 мин.</p>
                                            </div>
                                            <h3>Карьера <br>как проект</h3>
                                            <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 50 мин.</p>
                                            </div>
                                            <h3>Сколько я стою</h3>
                                            <p>Вместе с психологом поработаете над <br>самооценкой, чтобы <br>высоко оценивать <br>собственные
                                                навыки</p>
                                        </div>
                                        <div class="careerum-flex-column"></div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/career-path" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Укрепить самооценку" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 50 мин.</p>
                                            </div>
                                            <h3>Сколько я стою</h3>
                                            <p>Вместе с психологом <br>поработаете над <br>самооценкой, чтобы <br>высоко оценивать
                                                <br>собственные навыки</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>1 час 37 мин.</p>
                                            </div>
                                            <h3>Перфекционист — наш главный враг</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 27 мин.</p>
                                            </div>
                                            <h3>Как строить лич-<br>ные границы на <br>работе и в жизни</h3>
                                            <p>Научитесь экологично <br>обозначать и отстаивать границы</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>30 мин.</p>
                                            </div>
                                            <h3>Как найти в себе таланты</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти <br>себя в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и <br>поймете, на какой работе <br>сможете реализоваться</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее <br>резюме</h3>
                                            <p>Научитесь грамотно <br>формулировать достижения <br>и создадите сильное резюме, <br>которое точно
                                                рассмотрят</p>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/increase-confidence-level" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Полюбить свою работу" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 42 мин.</p>
                                            </div>
                                            <h3>Баланс между работой<br>и личной жизнью</h3>
                                            <p>Договоритесь с внутренним критиком и найдете свой work-life balance</p>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>11 часов 51 мин.</p>
                                            </div>
                                            <h3>Real me: как найти себя в работе <br>и жизни</h3>
                                            <p>Найдете свои таланты и поймете, на какой работе сможете реализоваться</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 минут</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет<br> продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений <br>и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>1 час 40 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Нелинейная продуктивность</h3>
                                                <p>Научитесь достигать хороших результатов в работе без сверхусилий и с более бережным
                                                    отношением к себе</p>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>1 час 16 мин.</p>
                                            </div>
                                            <h3>Карьера <br>как проект</h3>
                                            <p>Научитесь осознанно планировать долгосрочное карьерное развитие</p>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Видеоурок</p>
                                                <p>2 часа 17 мин.</p>
                                            </div>
                                            <h3>Ваши <br>мотиваторы</h3>
                                        </div>
                                        <div class="careerum-flex-column">
                                            <div class="careerum-vebinar-tab-item">
                                                <div>
                                                    <p>Вебинар</p>
                                                    <p>1 час 37 мин.</p>
                                                </div>
                                                <h3>The other side: recruiter’s view <br>on a job search</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/love-your-job" target="_blank">Посмотреть все материалы</a></div>
                            </div>
                            <div data-tile-name="Расти в кризис" class="careerum-tab-wrapper">
                                <div>
                                    <div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 32 мин.</p>
                                            </div>
                                            <h3>Карьера и психология: тревожность</h3>
                                        </div>
                                        <div class="careerum-vebinar-tab-item">
                                            <div>
                                                <p>Вебинар</p>
                                                <p>1 час 34 мин.</p>
                                            </div>
                                            <h3>Финансы <br>в кризис</h3>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Продающее резюме</h3>
                                            <p>Научитесь грамотно формулировать достижения <br>и создадите сильное резюме, которое точно
                                                рассмотрят</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>46 мин.</p>
                                            </div>
                                            <div>
                                                <h3>Hooked: как напи-сать цепляющее сопроводительное письмо</h3>
                                                <p>Научитесь писать письмо, которое поможет выделиться</p>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс</p>
                                                <p>2 часа 33 мин.</p>
                                            </div>
                                            <h3>Эффективное интервью</h3>
                                            <p>Поймете, как выгодно подать себя на интервью, и научитесь отвечать даже на самые сложные вопросы
                                                рекрутера</p>
                                        </div>
                                        <div class="careerum-master-tab-item">
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>27 минут</p>
                                            </div>
                                            <div>
                                                <h3>Менталитет<br> продавца</h3>
                                                <p>Преодолеете внутреннее сопротивление к продаже своих навыков, умений<br> и идей</p>
                                            </div>
                                        </div>
                                        <div class="careerum-course-tab-item">
                                            <div>
                                                <p>Курс10</p>
                                                <p>2 часа 16 мин.</p>
                                            </div>
                                            <h3>Умная стратегия поиска работы</h3>
                                            <p>Создадите пошаговый план поиска работы, который приведет вас к офферу мечты</p>
                                        </div>
                                        <div class="careerum-flex-column"></div>
                                    </div>
                                </div>
                                <div><a href="/ru-ru/club/content/growing-through-difficult-times" target="_blank">Посмотреть все материалы</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                `,
            }
        }
    });



    /* CALENDAR */
    componentName = 'careerum-calendar';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.03",
                },
                components: `
                <div>
                <div>
                    <div>
                        <h2>Ближайшие<br> мероприятия</h2>
                    </div>
                    <div>
                        <div>
                            <p><br draggable="true" data-highlightable="1">Групповое обучение<br>Real Me</p>
                        </div>
                        <div>
                            <input id="careerum-calendar-top-input" type="checkbox" name="">
                            <label for="careerum-calendar-top-input"><img
                                    src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1656712265367.svg" alt="">
                                <div>
                                    <div>
                                        <h3>Интерактивный формат обучения для участников клуба</h3>
                                        <p>Работая в мини-группах, вы в игровом формате достигнете нескольких результатов:</p>
                                        <ul>
                                            <li>найдете свою внутреннюю опору</li>
                                            <li>поймете свои сильные стороны и таланты</li>
                                            <li>узнаете, где и как вы сможете раскрыть свои таланты и воплотить ценности</li>
                                            <li>научитесь анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены профессии/ карьерного движения в текущей профессии</li>
                                        </ul>
                                    </div>
                                </div>
                            </label>
                            <p>23 февраля — 30 апреля</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <h4>Январь</h4>
                        <div>
                            <div class="mounth-header">
                                <div>
                                    <p>ПН</p>
                                </div>
                                <div>
                                    <p>ВТ</p>
                                </div>
                                <div>
                                    <p>СР</p>
                                </div>
                                <div>
                                    <p>ЧТ</p>
                                </div>
                                <div>
                                    <p>ПТ</p>
                                </div>
                                <div>
                                    <p>СБ</p>
                                </div>
                                <div>
                                    <p>ВС</p>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>1</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>2</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>3</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Что искать? Цель поиска работы"
                                    data-tooltip-text="Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную стратегию поиска"
                                    class="calendar-date">
                                    <p>4</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Что искать? Цель поиска работы</h5>
                                                <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                    границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                    стратегию поиска</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Что искать? Цель поиска работы</h5>
                                                <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                    границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                    стратегию поиска</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>5</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Воркшоп" data-tooltip-time="18:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов компании Google"
                                    class="calendar-date">
                                    <p>6</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов
                                                    компании Google</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>7</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                    data-tooltip-text="Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате. Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы"
                                    class="calendar-date">
                                    <p>8</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате.
                                                    Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте
                                                    волнующие карьерные вопросы</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>9</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>10</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как ставить цели в 2023 и следовать плану"
                                    data-tooltip-text="Вебинар карьерного эксперта Careerum Карины Кадеркаевой, на котором вы исследуете пошаговый алгоритм постановки целей и освоите инструменты планирования"
                                    class="calendar-date">
                                    <p>11</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как ставить цели в 2023 и следовать плану</h5>
                                                <p>Вебинар карьерного эксперта Careerum Карины Кадеркаевой, на котором вы исследуете
                                                    пошаговый алгоритм постановки целей и освоите инструменты планирования</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>12</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермайнд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                    вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>13</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Воркшоп" data-tooltip-time="11:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов компании Google"
                                    class="calendar-date">
                                    <p>14</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>11:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов
                                                    компании Google</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                    data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                    class="calendar-date">
                                    <p>15</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Английский разговорный клуб, уровень B2+</h5>
                                                <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                    карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                    языком одновременно</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>16</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>17</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как уверенно презентовать свои результаты руководителю"
                                    data-tooltip-text="Эксперт Careerum Арина Хома расскажет, Ккак проанализировать и сформулировать свою профессиональную самоценность и сделать руководителя партнером по достижению ваших целей. Вы научитесь презентовать свои профессиональные результаты эффективно и уверенно"
                                    class="calendar-date">
                                    <p>18</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Что искать? Цель поиска работы</h5>
                                                <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                    границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                    стратегию поиска</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как уверенно презентовать свои результаты руководителю</h5>
                                                <p>Эксперт Careerum Арина Хома расскажет, Ккак проанализировать и сформулировать
                                                    свою профессиональную самоценность и сделать руководителя партнером по
                                                    достижению ваших целей. Вы научитесь презентовать свои профессиональные
                                                    результаты эффективно и уверенно</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>19</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>20</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>21</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>22</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>23</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Обсуждение в группе"
                                    data-tooltip-time="19:00 МСК" data-tooltip-header="Книжный клуб"
                                    data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                    class="calendar-date">
                                    <p>24</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Обсуждение в группе</p>
                                                <p>19:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Книжный клуб</h5>
                                                <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                    нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как прокачивать нетворк и искать проводников к достижению ваших целей"
                                    data-tooltip-text="Вебинар Арины Хома, на котором вы познакомитесь с нетворк-концепцией бизнес-школы Сколково. Освоите практические инструменты, которые помогут вам легко заводить знакомства на мероприятиях"
                                    class="calendar-date">
                                    <p>25</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Что искать? Цель поиска работы</h5>
                                                <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                    границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                    стратегию поиска</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как прокачивать нетворк и искать проводников к достижению ваших целей</h5>
                                                <p>Вебинар Арины Хома, на котором вы познакомитесь с нетворк-концепцией бизнес-школы
                                                    Сколково. Освоите практические инструменты, которые помогут вам легко заводить
                                                    знакомства на мероприятиях</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>26</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермайнд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                    вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>27</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>28</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="offline" data-tooltip-name="Встреча в Москве"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                    data-tooltip-text="Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере"
                                    class="calendar-date">
                                    <p>29</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Встреча в Москве</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы
                                                    на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте
                                                    elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>30</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>31</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Что искать? Цель поиска работы"
                                    data-tooltip-text="Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную стратегию поиска"
                                    class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Что искать? Цель поиска работы</h5>
                                                <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                    границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                    стратегию поиска</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Что искать? Цель поиска работы</h5>
                                                <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                    границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                    стратегию поиска</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Февраль</h4>
                        <div>
                            <div class="mounth-header">
                                <div>
                                    <p>ПН</p>
                                </div>
                                <div>
                                    <p>ВТ</p>
                                </div>
                                <div>
                                    <p>СР</p>
                                </div>
                                <div>
                                    <p>ЧТ</p>
                                </div>
                                <div>
                                    <p>ПТ</p>
                                </div>
                                <div>
                                    <p>СБ</p>
                                </div>
                                <div>
                                    <p>ВС</p>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как обходить манипуляции на пути к целям"
                                    data-tooltip-text="На вебинаре вы вместе с экспертом Careerum Ариной Хома разберете различные типы манипуляторов на конкретных карьерных кейсах и отработаете практические инструменты, которые помогут обходить манипуляции в общении"
                                    class="calendar-date">
                                    <p>1</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Местер-класс</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Создайте продающее резюме</h5>
                                                <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                    Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                    выбранной локации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как обходить манипуляции на пути к целям</h5>
                                                <p>На вебинаре вы вместе с экспертом Careerum Ариной Хома разберете различные типы
                                                    манипуляторов на конкретных карьерных кейсах и отработаете практические
                                                    инструменты, которые помогут обходить манипуляции в общении</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать топливом вашу карьерную ракету"
                                    class="calendar-date">
                                    <p>2</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как справиться с паникой и стрессом"
                                    data-tooltip-text="Психолог Денис Осин расскажет, как справиться с такими нахлынувшими чувствами, как тревога и паника и научит техникам восстановления эмоционального баланса, которые помогут вам оставаться в ресурсе чтобы ни происходило"
                                    class="calendar-date">
                                    <p>3</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как справиться с паникой и стрессом</h5>
                                                <p>Психолог Денис Осин расскажет, как справиться с такими нахлынувшими чувствами,
                                                    как тревога и паника и научит техникам восстановления эмоционального баланса,
                                                    которые помогут вам оставаться в ресурсе чтобы ни происходило</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как справиться с паникой и стрессом</h5>
                                                <p>Психолог Денис Осин расскажет, как справиться с такими нахлынувшими чувствами,
                                                    как тревога и паника и научит техникам восстановления эмоционального баланса,
                                                    которые помогут вам оставаться в ресурсе чтобы ни происходило</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>4</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                    data-tooltip-text="Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате. Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы"
                                    class="calendar-date">
                                    <p>5</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Местер-класс</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Создайте продающее резюме</h5>
                                                <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                    Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                    выбранной локации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате.
                                                    Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте
                                                    волнующие карьерные вопросы</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                    data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                    class="calendar-date">
                                    <p>6</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                                <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                    сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                                <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                    сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>7</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Мастер-класс" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Подготовьтесь к интервью"
                                    data-tooltip-text="Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров"
                                    class="calendar-date">
                                    <p>8</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Подготовьтесь к интервью</h5>
                                                <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч
                                                    Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях
                                                    прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Мастер-класс</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Подготовьтесь к интервью</h5>
                                                <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч
                                                    Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях
                                                    прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>9</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермайнд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                    вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>10</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                    class="calendar-date">
                                    <p>11</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Встреча в Москве" data-tooltip-time="12:00 МСК"
                                    data-tooltip-header="#light_network"
                                    data-tooltip-text="Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие карьерные вопросы"
                                    class="calendar-date">
                                    <p>12</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Встреча в Москве</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                    в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                    карьерные вопросы</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Встреча в Москве</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                    в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                    карьерные вопросы</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                    data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                    class="calendar-date">
                                    <p>13</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                                <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                    вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                    возможна ваша реализация</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                                <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                    вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                    возможна ваша реализация</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Шоу историй"
                                    data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                    class="calendar-date">
                                    <p>14</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Шоу историй</h5>
                                                <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                    опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                    доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                    зрителя</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Шоу историй</h5>
                                                <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                    опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                    доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                    зрителя</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="17:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="С помощью инструментов компании Google избавитесь от блоков, которые мешают вам признавать свои достижения и прокачаете навыки самопрезентации"
                                    class="calendar-date">
                                    <p>15</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>17:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                    признавать свои достижения и прокачаете навыки самопрезентации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>17:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                    признавать свои достижения и прокачаете навыки самопрезентации</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                    class="calendar-date">
                                    <p>16</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>17</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>18</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                    data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                    class="calendar-date">
                                    <p>19</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Английский разговорный клуб, уровень B2+</h5>
                                                <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                    карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                    языком одновременно</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Выпускной фокус-кемпа"
                                    data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                    class="calendar-date">
                                    <p>20</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Выпускной фокус-кемпа</h5>
                                                <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                    фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                    получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Выпускной фокус-кемпа</h5>
                                                <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                    фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                    получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>21</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермайнд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                    вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Self-esteem"
                                    data-tooltip-text="Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и начать ценить свой профессионализм"
                                    class="calendar-date">
                                    <p>22</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Self-esteem</h5>
                                                <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                    Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                    начать ценить свой профессионализм</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Self-esteem</h5>
                                                <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                    Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                    начать ценить свой профессионализм</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Старт обучения" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Групповое обучение «Real me: как найти себя в работе и в жизни» с коучами Careerum"
                                    data-tooltip-text="Работая в мини-группах, вы в игровом формате достигнете нескольких результатов: найдете свою внутреннюю опору; поймете свои сильные стороны и таланты; узнаете,  где и как вы сможете раскрыть свои таланты и воплотить ценности; научитесь анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены профессии/ карьерного движения в текущей профессии"
                                    class="calendar-date">
                                    <p>23</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Старт обучения</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Групповое обучение «Real me: как найти себя в работе и в жизни» с коучами
                                                    Careerum</h5>
                                                <p>Работая в мини-группах, вы в игровом формате достигнете нескольких результатов:
                                                    найдете свою внутреннюю опору; поймете свои сильные стороны и таланты; узнаете,
                                                    где и как вы сможете раскрыть свои таланты и воплотить ценности; научитесь
                                                    анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены
                                                    профессии/ карьерного движения в текущей профессии</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>24</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                    class="calendar-date">
                                    <p>25</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="offline" data-tooltip-name="Встреча в Москве"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                    data-tooltip-text="Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере"
                                    class="calendar-date">
                                    <p>26</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Встреча в Москве</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы
                                                    на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте
                                                    elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Эфир" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Use your head: критическое мышление для жизни и работы"
                                    data-tooltip-text="Первый эфир от участницы клуба Полины Меркуловой, из которого вы узнаете о критическом мышлении и его пользе для анализа информации, принятия решений и коммуникации"
                                    class="calendar-date">
                                    <p>27</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Эфир</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Use your head: критическое мышление для жизни и работы</h5>
                                                <p>Первый эфир от участницы клуба Полины Меркуловой, из которого вы узнаете о
                                                    критическом мышлении и его пользе для анализа информации, принятия решений и
                                                    коммуникации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Эфир</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Use your head: критическое мышление для жизни и работы</h5>
                                                <p>Первый эфир от участницы клуба Полины Меркуловой, из которого вы узнаете о
                                                    критическом мышлении и его пользе для анализа информации, принятия решений и
                                                    коммуникации</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Обсуждение в группе"
                                    data-tooltip-time="19:00 МСК" data-tooltip-header="Книжный клуб"
                                    data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                    class="calendar-date">
                                    <p>28</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Обсуждение в группе</p>
                                                <p>19:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Книжный клуб</h5>
                                                <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                    нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Technology for Job Search"
                                    data-tooltip-text="Второй вебинар для участников фокус-кэмпа Get hired на котором вы научитесь использовать все каналы для поиска вакансий в разных странах"
                                    class="calendar-date">
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Technology for Job Search</h5>
                                                <p>Второй вебинар для участников фокус-кэмпа Get hired на котором вы научитесь
                                                    использовать все каналы для поиска вакансий в разных странах</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Technology for Job Search</h5>
                                                <p>Второй вебинар для участников фокус-кэмпа Get hired на котором вы научитесь
                                                    использовать все каналы для поиска вакансий в разных странах</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Март</h4>
                        <div>
                            <div class="mounth-header">
                                <div>
                                    <p>ПН</p>
                                </div>
                                <div>
                                    <p>ВТ</p>
                                </div>
                                <div>
                                    <p>СР</p>
                                </div>
                                <div>
                                    <p>ЧТ</p>
                                </div>
                                <div>
                                    <p>ПТ</p>
                                </div>
                                <div>
                                    <p>СБ</p>
                                </div>
                                <div>
                                    <p>ВС</p>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Карьерные установки: найти и обезвредить"
                                    data-tooltip-text="На практикуме, с помощью коуча Careerum Астры Висаевой, вы научитесь определять установки, видеть их выгоды и риски. Узнаете, как трансформировать негативные установки в позитивные на примере различных карьерных ситуаций"
                                    class="calendar-date">
                                    <p>1</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Местер-класс</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Создайте продающее резюме</h5>
                                                <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                    Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                    выбранной локации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Карьерные установки: найти и обезвредить</h5>
                                                <p>На практикуме, с помощью коуча Careerum Астры Висаевой, вы научитесь определять
                                                    установки, видеть их выгоды и риски. Узнаете, как трансформировать негативные
                                                    установки в позитивные на примере различных карьерных ситуаций</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать топливом вашу карьерную ракету"
                                    class="calendar-date">
                                    <p>2</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как справиться с паникой и стрессом"
                                    data-tooltip-text="Психолог Денис Осин расскажет, как справиться с такими нахлынувшими чувствами, как тревога и паника и научит техникам восстановления эмоционального баланса, которые помогут вам оставаться в ресурсе чтобы ни происходило"
                                    class="calendar-date">
                                    <p>3</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как справиться с паникой и стрессом</h5>
                                                <p>Психолог Денис Осин расскажет, как справиться с такими нахлынувшими чувствами,
                                                    как тревога и паника и научит техникам восстановления эмоционального баланса,
                                                    которые помогут вам оставаться в ресурсе чтобы ни происходило</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как справиться с паникой и стрессом</h5>
                                                <p>Психолог Денис Осин расскажет, как справиться с такими нахлынувшими чувствами,
                                                    как тревога и паника и научит техникам восстановления эмоционального баланса,
                                                    которые помогут вам оставаться в ресурсе чтобы ни происходило</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>4</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Как оставаться в ресурсном состоянии"
                                    data-tooltip-text="Сертифицированные коучи Careerum расскажут, как поддержать самих себя, найти внутренние и внешние точки опоры и иметь ресурсы, чтобы двигаться к своим целям даже когда вокруг неспокойно"
                                    class="calendar-date">
                                    <p>5</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как оставаться в ресурсном состоянии</h5>
                                                <p>Сертифицированные коучи Careerum расскажут, как поддержать самих себя, найти
                                                    внутренние и внешние точки опоры и иметь ресурсы, чтобы двигаться к своим целям
                                                    даже когда вокруг неспокойно</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Как оставаться в ресурсном состоянии</h5>
                                                <p>Сертифицированные коучи Careerum расскажут, как поддержать самих себя, найти
                                                    внутренние и внешние точки опоры и иметь ресурсы, чтобы двигаться к своим целям
                                                    даже когда вокруг неспокойно</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                    data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                    class="calendar-date">
                                    <p>6</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                                <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                    сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                                <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                    сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>7</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Таланты: ключ к работе по любви"
                                    data-tooltip-text="С помощью коуча, выполняя практические задания, поймете как и где применить свои таланты"
                                    class="calendar-date">
                                    <p>8</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>17:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                    признавать свои достижения и прокачаете навыки самопрезентации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Таланты: ключ к работе по любви</h5>
                                                <p>С помощью коуча, выполняя практические задания, поймете как и где применить свои
                                                    таланты</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>9</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермайнд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                    вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>10</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                    class="calendar-date">
                                    <p>11</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Встреча в Москве" data-tooltip-time="12:00 МСК"
                                    data-tooltip-header="#light_network"
                                    data-tooltip-text="Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие карьерные вопросы"
                                    class="calendar-date">
                                    <p>12</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Встреча в Москве</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                    в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                    карьерные вопросы</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Встреча в Москве</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#light_network</h5>
                                                <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                    в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                    карьерные вопросы</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                    data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                    class="calendar-date">
                                    <p>13</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                                <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                    вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                    возможна ваша реализация</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                                <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                    вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                    возможна ваша реализация</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Шоу историй"
                                    data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                    class="calendar-date">
                                    <p>14</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Шоу историй</h5>
                                                <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                    опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                    доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                    зрителя</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Шоу историй</h5>
                                                <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                    опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                    доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                    зрителя</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Ценности: опора профессионального развития"
                                    data-tooltip-text="С помощью коуча научитесь выявлять свои ценности и использовать это в рабочих и личных ситуациях"
                                    class="calendar-date">
                                    <p>15</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>17:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                    признавать свои достижения и прокачаете навыки самопрезентации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Ценности: опора профессионального развития</h5>
                                                <p>С помощью коуча научитесь выявлять свои ценности и использовать это в рабочих и
                                                    личных ситуациях</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                    class="calendar-date">
                                    <p>16</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                    мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                    топливом вашу карьерную ракету</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>17</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>18</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                    data-tooltip-text="Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык владения английским языком и чувствовать себя уверенно в любых ситуациях, даже работая в международной компании"
                                    class="calendar-date">
                                    <p>19</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Английский разговорный клуб, уровень B2+</h5>
                                                <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                    владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                    работая в международной компании</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Английский разговорный клуб, уровень B2+</h5>
                                                <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                    владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                    работая в международной компании</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Выпускной фокус-кемпа"
                                    data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                    class="calendar-date">
                                    <p>20</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Выпускной фокус-кемпа</h5>
                                                <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                    фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                    получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Выпускной фокус-кемпа</h5>
                                                <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                    фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                    получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>21</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Мотиваторы: ускорители вашей карьеры"
                                    data-tooltip-text="Поймете, что такое мотиваторы, как их выявлять и применять в работе, чтобы сделать её приятнее и эффективнее"
                                    class="calendar-date">
                                    <p>22</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>17:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                    признавать свои достижения и прокачаете навыки самопрезентации</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мотиваторы: ускорители вашей карьеры</h5>
                                                <p>Поймете, что такое мотиваторы, как их выявлять и применять в работе, чтобы
                                                    сделать её приятнее и эффективнее</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>23</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермайнд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                    вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>24</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                    data-tooltip-header="#IamRemarkable"
                                    data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                    class="calendar-date">
                                    <p>25</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Воркшоп</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>#IamRemarkable</h5>
                                                <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                    среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                    data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                    class="calendar-date">
                                    <p>26</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>12:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Английский разговорный клуб, уровень B2+</h5>
                                                <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                    карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                    языком одновременно</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Выпускной фокус-кемпа"
                                    data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                    class="calendar-date">
                                    <p>27</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Выпускной фокус-кемпа</h5>
                                                <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                    фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                    получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Онлайн-встреча</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Выпускной фокус-кемпа</h5>
                                                <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                    фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                    получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="network" data-tooltip-name="Обсуждение в группе"
                                    data-tooltip-time="19:00 МСК" data-tooltip-header="Книжный клуб"
                                    data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                    class="calendar-date">
                                    <p>28</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Обсуждение в группе</p>
                                                <p>19:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Книжный клуб</h5>
                                                <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                    нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                    data-tooltip-header="Баланс на работе и в жизни: миф или реальность?"
                                    data-tooltip-text="Сделаете упражнения, которые помогут понять, на каких аспектах карьеры и жизни стоит сфокусироваться прямо сейчас, чтобы привести жизнь и работу в баланс"
                                    class="calendar-date">
                                    <p>29</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Вебинар</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Self-esteem</h5>
                                                <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                    Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                    начать ценить свой профессионализм</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Практикум</p>
                                                <p>20:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Баланс на работе и в жизни: миф или реальность?</h5>
                                                <p>Сделаете упражнения, которые помогут понять, на каких аспектах карьеры и жизни
                                                    стоит сфокусироваться прямо сейчас, чтобы привести жизнь и работу в баланс</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                    data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                    data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                    class="calendar-date">
                                    <p>30</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p>Работа в мини-группах</p>
                                                <p>18:00 МСК</p>
                                            </div>
                                            <div>
                                                <h5>Мастермаинд</h5>
                                                <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                    сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                    желаемому результату</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p>31</p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                    data-tooltip-text="" class="calendar-date">
                                    <p></p>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="calendar-tooltip"
                                        style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                        <div>
                                            <div>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <h5></h5>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Обучение</p>
                    </div>
                    <div>
                        <p>Нетворкинг</p>
                    </div>
                    <div>
                        <p>Welcome to Careerum </p>
                    </div>
                    <div>
                        <p>Офлайн-ивент</p>
                    </div>
                </div>
            </div>
                `,
            }
        }
    });


    componentName = 'careerum-calendar-tablet';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.03",
                },
                components: `
        <div>
            <div>
                <div>
                    <h2>Ближайшие<br> мероприятия</h2>
                </div>
                <div>
                    <div>
                        <p><br draggable="true" data-highlightable="1">Групповое обучение Real Me</p>
                    </div>
                    <div>
                        <input id="careerum-calendar-top-input-tablet" type="checkbox" name="">
                        <label for="careerum-calendar-top-input-tablet">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1656712369971.svg" alt="">
                            <div>
                                <div>
                                    <h3>Интерактивный формат обучения для участников клуба</h3>
                                    <p>Работая в мини-группах, вы в игровом формате достигнете нескольких результатов:</p>
                                    <ul>
                                        <li>найдете свою внутреннюю опору</li>
                                        <li>поймете свои сильные стороны и таланты</li>
                                        <li>узнаете, где и как вы сможете раскрыть свои таланты и воплотить ценности</li>
                                        <li>научитесь анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены профессии/ карьерного движения в текущей профессии</li>
                                    </ul>
                                </div>
                            </div>
                        </label>
                        <p>23 февраля — 30 апреля</p>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <h4>Январь</h4>
                    <div>
                        <div class="mounth-header">
                            <div>
                                <p>ПН</p>
                            </div>
                            <div>
                                <p>ВТ</p>
                            </div>
                            <div>
                                <p>СР</p>
                            </div>
                            <div>
                                <p>ЧТ</p>
                            </div>
                            <div>
                                <p>ПТ</p>
                            </div>
                            <div>
                                <p>СБ</p>
                            </div>
                            <div>
                                <p>ВС</p>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>1</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>2</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>3</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Что искать? Цель поиска работы"
                                data-tooltip-text="Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную стратегию поиска"
                                class="calendar-date">
                                <p>4</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>5</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Воркшоп" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов компании Google"
                                class="calendar-date">
                                <p>6</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов
                                                компании Google</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>7</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате. Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>8</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате.
                                                Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте
                                                волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>9</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>10</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как ставить цели в 2023 и следовать плану"
                                data-tooltip-text="Вебинар карьерного эксперта Careerum Карины Кадеркаевой, на котором вы исследуете пошаговый алгоритм постановки целей и освоите инструменты планирования"
                                class="calendar-date">
                                <p>11</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как ставить цели в 2023 и следовать плану</h5>
                                            <p>Вебинар карьерного эксперта Careerum Карины Кадеркаевой, на котором вы исследуете
                                                пошаговый алгоритм постановки целей и освоите инструменты планирования</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>12</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>13</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Воркшоп" data-tooltip-time="11:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов компании Google"
                                class="calendar-date">
                                <p>14</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>11:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов
                                                компании Google</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                class="calendar-date">
                                <p>15</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                языком одновременно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>16</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>17</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как уверенно презентовать свои результаты руководителю"
                                data-tooltip-text="Эксперт Careerum Арина Хома расскажет, Ккак проанализировать и сформулировать свою профессиональную самоценность и сделать руководителя партнером по достижению ваших целей. Вы научитесь презентовать свои профессиональные результаты эффективно и уверенно"
                                class="calendar-date">
                                <p>18</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как уверенно презентовать свои результаты руководителю</h5>
                                            <p>Эксперт Careerum Арина Хома расскажет, Ккак проанализировать и сформулировать
                                                свою профессиональную самоценность и сделать руководителя партнером по
                                                достижению ваших целей. Вы научитесь презентовать свои профессиональные
                                                результаты эффективно и уверенно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>19</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>20</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>21</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>22</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>23</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Обсуждение" data-tooltip-time="19:00 МСК"
                                data-tooltip-header="Книжный клуб"
                                data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                class="calendar-date">
                                <p>24</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Обсуждение</p>
                                            <p>19:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Книжный клуб</h5>
                                            <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как прокачивать нетворк и искать проводников к достижению ваших целей"
                                data-tooltip-text="Вебинар Арины Хома, на котором вы познакомитесь с нетворк-концепцией бизнес-школы Сколково. Освоите практические инструменты, которые помогут вам легко заводить знакомства на мероприятиях"
                                class="calendar-date">
                                <p>25</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как прокачивать нетворк и искать проводников к достижению ваших целей</h5>
                                            <p>Вебинар Арины Хома, на котором вы познакомитесь с нетворк-концепцией бизнес-школы
                                                Сколково. Освоите практические инструменты, которые помогут вам легко заводить
                                                знакомства на мероприятиях</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>26</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>27</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>28</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="offline" data-tooltip-name="Встреча в Москве"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере"
                                class="calendar-date">
                                <p>29</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Встреча в Москве</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы
                                                на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте
                                                elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>30</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>31</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Что искать? Цель поиска работы"
                                data-tooltip-text="Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную стратегию поиска"
                                class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="careerum-calendar-mounth">
                    <h4>Февраль</h4>
                    <div>
                        <div class="mounth-header">
                            <div>
                                <p>ПН</p>
                            </div>
                            <div>
                                <p>ВТ</p>
                            </div>
                            <div>
                                <p>СР</p>
                            </div>
                            <div>
                                <p>ЧТ</p>
                            </div>
                            <div>
                                <p>ПТ</p>
                            </div>
                            <div>
                                <p>СБ</p>
                            </div>
                            <div>
                                <p>ВС</p>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как обходить манипуляции на пути к целям"
                                data-tooltip-text="На вебинаре вы вместе с экспертом Careerum Ариной Хома разберете различные типы манипуляторов на конкретных карьерных кейсах и отработаете практические инструменты, которые помогут обходить манипуляции в общении"
                                class="calendar-date">
                                <p>1</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Создайте продающее резюме</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                выбранной локации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как обходить манипуляции на пути к целям</h5>
                                            <p>На вебинаре вы вместе с экспертом Careerum Ариной Хома разберете различные типы
                                                манипуляторов на конкретных карьерных кейсах и отработаете практические
                                                инструменты, которые помогут обходить манипуляции в общении</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>2</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>3</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>4</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате. Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>5</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате.
                                                Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте
                                                волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                class="calendar-date">
                                <p>6</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>7</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Мастер-класс" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Подготовьтесь к интервью"
                                data-tooltip-text="Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров"
                                class="calendar-date">
                                <p>8</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Подготовьтесь к интервью</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч
                                                Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях
                                                прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Подготовьтесь к интервью</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч
                                                Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях
                                                прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>9</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>10</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <p>11</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Встреча в Москве" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>12</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Встреча в Москве</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Встреча в Москве</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                class="calendar-date">
                                <p>13</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Шоу историй"
                                data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                class="calendar-date">
                                <p>14</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="17:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="С помощью инструментов компании Google избавитесь от блоков, которые мешают вам признавать свои достижения и прокачаете навыки самопрезентации"
                                class="calendar-date">
                                <p>15</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>17:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                признавать свои достижения и прокачаете навыки самопрезентации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>17:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                признавать свои достижения и прокачаете навыки самопрезентации</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>16</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>17</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>18</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                class="calendar-date">
                                <p>19</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                языком одновременно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Выпускной фокус-кемпа"
                                data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                class="calendar-date">
                                <p>20</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>21</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Self-esteem"
                                data-tooltip-text="Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и начать ценить свой профессионализм"
                                class="calendar-date">
                                <p>22</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Self-esteem</h5>
                                            <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                начать ценить свой профессионализм</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Self-esteem</h5>
                                            <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                начать ценить свой профессионализм</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Старт обучения" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Групповое обучение «Real me: как найти себя в работе и в жизни» с коучами Careerum"
                                data-tooltip-text="Работая в мини-группах, вы в игровом формате достигнете нескольких результатов: найдете свою внутреннюю опору; поймете свои сильные стороны и таланты; узнаете,  где и как вы сможете раскрыть свои таланты и воплотить ценности; научитесь анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены профессии/ карьерного движения в текущей профессии"
                                class="calendar-date">
                                <p>23</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Старт обучения</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Групповое обучение «Real me: как найти себя в работе и в жизни» с коучами
                                                Careerum</h5>
                                            <p>Работая в мини-группах, вы в игровом формате достигнете нескольких результатов:
                                                найдете свою внутреннюю опору; поймете свои сильные стороны и таланты; узнаете,
                                                где и как вы сможете раскрыть свои таланты и воплотить ценности; научитесь
                                                анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены
                                                профессии/ карьерного движения в текущей профессии</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>24</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <p>25</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="offline" data-tooltip-name="Встреча в Москве"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере"
                                class="calendar-date">
                                <p>26</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Встреча в Москве</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы
                                                на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте
                                                elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Выпускной фокус-кемпа"
                                data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                class="calendar-date">
                                <p>27</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Обсуждение" data-tooltip-time="19:00 МСК"
                                data-tooltip-header="Книжный клуб"
                                data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                class="calendar-date">
                                <p>28</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Обсуждение</p>
                                            <p>19:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Книжный клуб</h5>
                                            <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Self-esteem"
                                data-tooltip-text="Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и начать ценить свой профессионализм"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Self-esteem</h5>
                                            <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                начать ценить свой профессионализм</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Self-esteem</h5>
                                            <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                начать ценить свой профессионализм</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Общайтесь с близкими по духу людьми на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы и получайте заряд мотивации"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Общайтесь с близкими по духу людьми на актуальные темы клуба, в теплой
                                                доверительной атмосфере обсуждайте волнующие карьерные вопросы и получайте заряд
                                                мотивации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Общайтесь с близкими по духу людьми на актуальные темы клуба, в теплой
                                                доверительной атмосфере обсуждайте волнующие карьерные вопросы и получайте заряд
                                                мотивации</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="careerum-calendar-mounth">
                    <h4>Март</h4>
                    <div>
                        <div class="mounth-header">
                            <div>
                                <p>ПН</p>
                            </div>
                            <div>
                                <p>ВТ</p>
                            </div>
                            <div>
                                <p>СР</p>
                            </div>
                            <div>
                                <p>ЧТ</p>
                            </div>
                            <div>
                                <p>ПТ</p>
                            </div>
                            <div>
                                <p>СБ</p>
                            </div>
                            <div>
                                <p>ВС</p>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Карьерные установки: найти и обезвредить"
                                data-tooltip-text="На практикуме, с помощью коуча Careerum Астры Висаевой, вы научитесь определять установки, видеть их выгоды и риски. Узнаете, как трансформировать негативные установки в позитивные на примере различных карьерных ситуаций"
                                class="calendar-date">
                                <p>1</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Создайте продающее резюме</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                выбранной локации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Карьерные установки: найти и обезвредить</h5>
                                            <p>На практикуме, с помощью коуча Careerum Астры Висаевой, вы научитесь определять
                                                установки, видеть их выгоды и риски. Узнаете, как трансформировать негативные
                                                установки в позитивные на примере различных карьерных ситуаций</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>2</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>3</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>4</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча " data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Нетворкинговое мероприятие, на котором вы сможете пообщаться с участниками на актуальные темы клуба, завести новые знакомства и в теплой доверительной атмосфере обсудить волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>5</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча </p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Нетворкинговое мероприятие, на котором вы сможете пообщаться с участниками на
                                                актуальные темы клуба, завести новые знакомства и в теплой доверительной
                                                атмосфере обсудить волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча </p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Нетворкинговое мероприятие, на котором вы сможете пообщаться с участниками на
                                                актуальные темы клуба, завести новые знакомства и в теплой доверительной
                                                атмосфере обсудить волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                class="calendar-date">
                                <p>6</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>7</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Таланты: ключ к работе по любви"
                                data-tooltip-text="С помощью коуча, выполняя практические задания, поймете как и где применить свои таланты"
                                class="calendar-date">
                                <p>8</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>17:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                признавать свои достижения и прокачаете навыки самопрезентации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Таланты: ключ к работе по любви</h5>
                                            <p>С помощью коуча, выполняя практические задания, поймете как и где применить свои
                                                таланты</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>9</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>10</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <p>11</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Встреча в Москве" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>12</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Встреча в Москве</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Встреча в Москве</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                class="calendar-date">
                                <p>13</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Шоу историй"
                                data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                class="calendar-date">
                                <p>14</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Ценности: опора профессионального развития"
                                data-tooltip-text="С помощью коуча научитесь выявлять свои ценности и использовать это в рабочих и личных ситуациях"
                                class="calendar-date">
                                <p>15</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>17:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                признавать свои достижения и прокачаете навыки самопрезентации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Ценности: опора профессионального развития</h5>
                                            <p>С помощью коуча научитесь выявлять свои ценности и использовать это в рабочих и
                                                личных ситуациях</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>16</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>17</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>18</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык владения английским языком и чувствовать себя уверенно в любых ситуациях, даже работая в международной компании"
                                class="calendar-date">
                                <p>19</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Выпускной фокус-кемпа"
                                data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                class="calendar-date">
                                <p>20</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>21</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Мотиваторы: ускорители вашей карьеры"
                                data-tooltip-text="Поймете, что такое мотиваторы, как их выявлять и применять в работе, чтобы сделать её приятнее и эффективнее"
                                class="calendar-date">
                                <p>22</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>17:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>С помощью инструментов компании Google избавитесь от блоков, которые мешают вам
                                                признавать свои достижения и прокачаете навыки самопрезентации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мотиваторы: ускорители вашей карьеры</h5>
                                            <p>Поймете, что такое мотиваторы, как их выявлять и применять в работе, чтобы
                                                сделать её приятнее и эффективнее</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>23</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>24</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <p>25</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                class="calendar-date">
                                <p>26</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                языком одновременно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Выпускной фокус-кемпа"
                                data-tooltip-text="Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы получите ответы на оставшиеся вопросы и наметите дальнейший план действий"
                                class="calendar-date">
                                <p>27</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Выпускной фокус-кемпа</h5>
                                            <p>Онлайн-встреча с карьерным экспертом и участниками, для подведения итогов
                                                фокус-кэмпа «Real me.Надо: третий элемент работы по любви». На встрече вы
                                                получите ответы на оставшиеся вопросы и наметите дальнейший план действий</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Обсуждение" data-tooltip-time="19:00 МСК"
                                data-tooltip-header="Книжный клуб"
                                data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                class="calendar-date">
                                <p>28</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Обсуждение</p>
                                            <p>19:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Книжный клуб</h5>
                                            <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Баланс на работе и в жизни: миф или реальность?"
                                data-tooltip-text="Сделаете упражнения, которые помогут понять, на каких аспектах карьеры и жизни стоит сфокусироваться прямо сейчас, чтобы привести жизнь и работу в баланс"
                                class="calendar-date">
                                <p>29</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Self-esteem</h5>
                                            <p>Первый вебинар для участников фокус-кэмпа Get hired от карьерного эксперта Карины
                                                Кадеркаевой, из которого вы узнаете, как избавиться от синдрома самозванца и
                                                начать ценить свой профессионализм</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Баланс на работе и в жизни: миф или реальность?</h5>
                                            <p>Сделаете упражнения, которые помогут понять, на каких аспектах карьеры и жизни
                                                стоит сфокусироваться прямо сейчас, чтобы привести жизнь и работу в баланс</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>30</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>31</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Общайтесь с близкими по духу людьми на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы и получайте заряд мотивации"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Общайтесь с близкими по духу людьми на актуальные темы клуба, в теплой
                                                доверительной атмосфере обсуждайте волнующие карьерные вопросы и получайте заряд
                                                мотивации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Общайтесь с близкими по духу людьми на актуальные темы клуба, в теплой
                                                доверительной атмосфере обсуждайте волнующие карьерные вопросы и получайте заряд
                                                мотивации</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Обучение</p>
                    </div>
                    <div>
                        <p>Нетворкинг</p>
                    </div>
                    <div>
                        <p>Офлайн-ивент</p>
                    </div>
                    <div>
                        <p>Welcome to Careerum </p>
                    </div>
                </div>
            </div>
        </div>
                `,
            }
        }
    });


    componentName = 'careerum-calendar-mobile';
    domc.addType(componentName, {
        isComponent: (el: HTMLElement) => !!el.classList?.contains(componentName),
        model: {
            defaults: {
                ...traits,
                tagName: 'div',
                classes: componentName,
                droppable: droppableWrapperSelector,
                draggable: droppableWrapperSelector,
                attributes: { 
                    version: "1.03",
                },
                components: `
                <div>
            <div>
                <div>
                    <h2>Ближайшие<br> мероприятия</h2>
                </div>
                <div>
                    <div>
                        <p><br>Групповое обучение<br>Real Me<br></p>
                    </div>
                    <div>
                        <p>23 февраля — 30 апреля</p>
                        <input id="careerum-calendar-top-input-mobile" type="checkbox" name="">
                        <label for="careerum-calendar-top-input-mobile">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1656712429975.svg" alt="">
                            <div>
                                <div>
                                    <h3>Интерактивный формат обучения для участников клуба</h3>
                                    <p>Работая в мини-группах, вы в игровом формате достигнете нескольких результатов:</p>
                                    <ul>
                                        <li>найдете свою внутреннюю опору</li>
                                        <li>поймете свои сильные стороны и таланты</li>
                                        <li>узнаете, где и как вы сможете раскрыть свои таланты и воплотить ценности</li>
                                        <li>научитесь анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены профессии/ карьерного движения в текущей профессии</li>
                                    </ul>
                                </div>
                            </div>
                        </label>
                    </div><img src="https://fra1.digitaloceanspaces.com/devabackenduploads/files-1656712444177.svg" alt="">
                </div>
            </div>
            <div>
                <div>
                    <h4>Январь</h4>
                    <div>
                        <div class="mounth-header">
                            <div>
                                <p>ПН</p>
                            </div>
                            <div>
                                <p>ВТ</p>
                            </div>
                            <div>
                                <p>СР</p>
                            </div>
                            <div>
                                <p>ЧТ</p>
                            </div>
                            <div>
                                <p>ПТ</p>
                            </div>
                            <div>
                                <p>СБ</p>
                            </div>
                            <div>
                                <p>ВС</p>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>1</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>2</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>3</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Что искать? Цель поиска работы"
                                data-tooltip-text="Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную стратегию поиска"
                                class="calendar-date">
                                <p>4</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>5</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Воркшоп" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов компании Google"
                                class="calendar-date">
                                <p>6</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов
                                                компании Google</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>7</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате. Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>8</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате.
                                                Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте
                                                волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>9</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>10</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как ставить цели в 2023 и следовать плану"
                                data-tooltip-text="Вебинар карьерного эксперта Careerum Карины Кадеркаевой, на котором вы исследуете пошаговый алгоритм постановки целей и освоите инструменты планирования"
                                class="calendar-date">
                                <p>11</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как ставить цели в 2023 и следовать плану</h5>
                                            <p>Вебинар карьерного эксперта Careerum Карины Кадеркаевой, на котором вы исследуете
                                                пошаговый алгоритм постановки целей и освоите инструменты планирования</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>12</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>13</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Воркшоп" data-tooltip-time="11:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов компании Google"
                                class="calendar-date">
                                <p>14</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>11:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Избавьтесь от блоков и прокачайте навыки самопрезентации с помощью инструментов
                                                компании Google</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                class="calendar-date">
                                <p>15</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                языком одновременно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>16</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>17</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как уверенно презентовать свои результаты руководителю"
                                data-tooltip-text="Эксперт Careerum Арина Хома расскажет, Ккак проанализировать и сформулировать свою профессиональную самоценность и сделать руководителя партнером по достижению ваших целей. Вы научитесь презентовать свои профессиональные результаты эффективно и уверенно"
                                class="calendar-date">
                                <p>18</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как уверенно презентовать свои результаты руководителю</h5>
                                            <p>Эксперт Careerum Арина Хома расскажет, Ккак проанализировать и сформулировать
                                                свою профессиональную самоценность и сделать руководителя партнером по
                                                достижению ваших целей. Вы научитесь презентовать свои профессиональные
                                                результаты эффективно и уверенно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>19</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>20</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>21</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>22</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>23</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="19:00 МСК" data-tooltip-header="Книжный клуб"
                                data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                class="calendar-date">
                                <p>24</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>19:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Книжный клуб</h5>
                                            <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как прокачивать нетворк и искать проводников к достижению ваших целей"
                                data-tooltip-text="Вебинар Арины Хома, на котором вы познакомитесь с нетворк-концепцией бизнес-школы Сколково. Освоите практические инструменты, которые помогут вам легко заводить знакомства на мероприятиях."
                                class="calendar-date">
                                <p>25</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как прокачивать нетворк и искать проводников к достижению ваших целей</h5>
                                            <p>Вебинар Арины Хома, на котором вы познакомитесь с нетворк-концепцией бизнес-школы
                                                Сколково. Освоите практические инструменты, которые помогут вам легко заводить
                                                знакомства на мероприятиях.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>26</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>27</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>28</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="offline" data-tooltip-name="Офлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере"
                                class="calendar-date">
                                <p>29</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Офлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы
                                                на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте
                                                elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>30</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>31</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Что искать? Цель поиска работы"
                                data-tooltip-text="Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную стратегию поиска"
                                class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Что искать? Цель поиска работы</h5>
                                            <p>Практикум для участников фокус-кэмпа «Move.ON: от нулевых откликов до карьеры без
                                                границ», на котором вы определите свои «Хочу-Могу-Надо» и составите грамотную
                                                стратегию поиска</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Работа в мини-группах"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Работа в мини-группах</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию и мнение со стороны. Так вы
                                                сможете посмотреть на нее по-новому и понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="careerum-calendar-mounth">
                    <h4>Февраль</h4>
                    <div>
                        <div class="mounth-header">
                            <div>
                                <p>ПН</p>
                            </div>
                            <div>
                                <p>ВТ</p>
                            </div>
                            <div>
                                <p>СР</p>
                            </div>
                            <div>
                                <p>ЧТ</p>
                            </div>
                            <div>
                                <p>ПТ</p>
                            </div>
                            <div>
                                <p>СБ</p>
                            </div>
                            <div>
                                <p>ВС</p>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Вебинар" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Как обходить манипуляции на пути к целям"
                                data-tooltip-text="На вебинаре вы вместе с экспертом Careerum Ариной Хома разберете различные типы манипуляторов на конкретных карьерных кейсах и отработаете практические инструменты, которые помогут обходить манипуляции в общении"
                                class="calendar-date">
                                <p>1</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Создайте продающее резюме</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                выбранной локации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Вебинар</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Как обходить манипуляции на пути к целям</h5>
                                            <p>На вебинаре вы вместе с экспертом Careerum Ариной Хома разберете различные типы
                                                манипуляторов на конкретных карьерных кейсах и отработаете практические
                                                инструменты, которые помогут обходить манипуляции в общении</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>2</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>3</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>4</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате. Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>5</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Знакомьтесь с другими участниками и усиливайте нетворк в онлайн-формате.
                                                Общайтесь на актуальные темы клуба, в теплой доверительной атмосфере обсуждайте
                                                волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                class="calendar-date">
                                <p>6</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                class="calendar-date">
                                <p>7</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Мастер-класс" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Подготовьтесь к интервью"
                                data-tooltip-text="Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров"
                                class="calendar-date">
                                <p>8</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Подготовьтесь к интервью</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч
                                                Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях
                                                прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Подготовьтесь к интервью</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором эксперты и коуч
                                                Careerum помогут улучшить вашу самопрезентацию, расскажут об особенностях
                                                прохождения разных собеседований и научат отвечать на сложные вопросы рекрутеров
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>9</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Первичный поиск отраслей"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы научитесь анализировать рынок и находить варианты отраслей, подходящие именно вам"
                                class="calendar-date">
                                <p>10</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Первичный поиск отраслей</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы научитесь анализировать рынок и находить варианты отраслей, подходящие именно
                                                вам</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Первичный поиск отраслей</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы научитесь анализировать рынок и находить варианты отраслей, подходящие именно
                                                вам</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <p>11</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Офлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>12</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Офлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Офлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 – 14:00"
                                data-tooltip-header="14#IamRemarkable"
                                data-tooltip-text="Усилите навыки самопродвижения и самопрезентации и разберете предубеждения, которые вам мешают заявить о себе"
                                class="calendar-date">
                                <p>13</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                class="calendar-date">
                                <p>14</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Шоу историй"
                                data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                class="calendar-date">
                                <p>15</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>16</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Валидация карьерных гипотез"
                                data-tooltip-text="Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с учетом созданных вами гипотез"
                                class="calendar-date">
                                <p>17</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>18</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                class="calendar-date">
                                <p>19</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                языком одновременно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 – 14:00"
                                data-tooltip-header="14#IamRemarkable"
                                data-tooltip-text="Усилите навыки самопродвижения и самопрезентации и разберете предубеждения, которые вам мешают заявить о себе"
                                class="calendar-date">
                                <p>20</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>21</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Шоу историй"
                                data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                class="calendar-date">
                                <p>22</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Старт обучения" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Групповое обучение «Real me: как найти себя в работе и в жизни» с коучами Careerum"
                                data-tooltip-text="Работая в мини-группах, вы в игровом формате достигнете нескольких результатов: найдете свою внутреннюю опору; поймете свои сильные стороны и таланты; узнаете,  где и как вы сможете раскрыть свои таланты и воплотить ценности; научитесь анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены профессии/ карьерного движения в текущей профессии"
                                class="calendar-date">
                                <p>23</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Старт обучения</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Групповое обучение «Real me: как найти себя в работе и в жизни» с коучами
                                                Careerum</h5>
                                            <p>Работая в мини-группах, вы в игровом формате достигнете нескольких результатов:
                                                найдете свою внутреннюю опору; поймете свои сильные стороны и таланты; узнаете,
                                                где и как вы сможете раскрыть свои таланты и воплотить ценности; научитесь
                                                анализировать рынок труда и сформулируете гипотезы выбора профессии/ смены
                                                профессии/ карьерного движения в текущей профессии</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Валидация карьерных гипотез"
                                data-tooltip-text="Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с учетом созданных вами гипотез"
                                class="calendar-date">
                                <p>24</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>25</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="offline" data-tooltip-name="Офлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере"
                                class="calendar-date">
                                <p>26</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Офлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства, обсуждайте актуальные темы клуба и волнующие вопросы
                                                на тему карьеры. Обменивайтесь опытом с другими участниками. Практикуйте
                                                elevator pitch и озвучивание google key facts в комфортной дружелюбной атмосфере
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 – 14:00"
                                data-tooltip-header="14#IamRemarkable"
                                data-tooltip-text="Усилите навыки самопродвижения и самопрезентации и разберете предубеждения, которые вам мешают заявить о себе"
                                class="calendar-date">
                                <p>27</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="19:00 МСК" data-tooltip-header="Книжный клуб"
                                data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                class="calendar-date">
                                <p>28</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>19:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Книжный клуб</h5>
                                            <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Шоу историй"
                                data-tooltip-text="Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой опыт и достижения, продать идею, расположить к себе собеседника и вызвать доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве зрителя"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Шоу историй</h5>
                                            <p>Разовьете навык сторителлинга, который поможет в будущем грамотно упаковать свой
                                                опыт и достижения, продать идею, расположить к себе собеседника и вызвать
                                                доверие. Если вы пока не готовы быть спикером, можно участвовать в качестве
                                                зрителя</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Валидация карьерных гипотез"
                                data-tooltip-text="Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с учетом созданных вами гипотез"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык владения английским языком и чувствовать себя уверенно в любых ситуациях, даже работая в международной компании"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="careerum-calendar-mounth">
                    <h4>Март</h4>
                    <div>
                        <div class="mounth-header">
                            <div>
                                <p>ПН</p>
                            </div>
                            <div>
                                <p>ВТ</p>
                            </div>
                            <div>
                                <p>СР</p>
                            </div>
                            <div>
                                <p>ЧТ</p>
                            </div>
                            <div>
                                <p>ПТ</p>
                            </div>
                            <div>
                                <p>СБ</p>
                            </div>
                            <div>
                                <p>ВС</p>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p></p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Карьерные установки: найти и обезвредить"
                                data-tooltip-text="На практикуме, с помощью коуча Careerum Астры Висаевой, вы научитесь определять установки, видеть их выгоды и риски. Узнаете, как трансформировать негативные установки в позитивные на примере различных карьерных ситуаций"
                                class="calendar-date">
                                <p>1</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Мастер-класс</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Создайте продающее резюме</h5>
                                            <p>Мастер-класс в рамках интенсива «3 шага до оффера», на котором CV-эксперт София
                                                Бессонова расскажет, как сделать апгрейд резюме с учетом карьерного запроса и
                                                выбранной локации</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Карьерные установки: найти и обезвредить</h5>
                                            <p>На практикуме, с помощью коуча Careerum Астры Висаевой, вы научитесь определять
                                                установки, видеть их выгоды и риски. Узнаете, как трансформировать негативные
                                                установки в позитивные на примере различных карьерных ситуаций</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>2</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и заряжать
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>3</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>4</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Нетворкинговое мероприятие, на котором вы сможете пообщаться с участниками на актуальные темы клуба, завести новые знакомства и в теплой доверительной атмосфере обсудить волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>5</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Нетворкинговое мероприятие, на котором вы сможете пообщаться с участниками на
                                                актуальные темы клуба, завести новые знакомства и в теплой доверительной
                                                атмосфере обсудить волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Нетворкинговое мероприятие, на котором вы сможете пообщаться с участниками на
                                                актуальные темы клуба, завести новые знакомства и в теплой доверительной
                                                атмосфере обсудить волнующие карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                class="calendar-date">
                                <p>6</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Знакомство. Запрос на фокус-кэмп"
                                data-tooltip-text="Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и сформулируете цель, которую хотите достичь на фокус-кэмпе"
                                class="calendar-date">
                                <p>7</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Знакомство. Запрос на фокус-кэмп</h5>
                                            <p>Живая сессия, на которой вы познакомитесь с карьерным экспертом и участниками и
                                                сформулируете цель, которую хотите достичь на фокус-кэмпе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00"
                                data-tooltip-header="Таланты: ключ к работе по любви"
                                data-tooltip-text="С помощью коуча, выполняя практические задания, поймете как и где применить свои таланты"
                                class="calendar-date">
                                <p>8</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00</p>
                                        </div>
                                        <div>
                                            <h5>Таланты: ключ к работе по любви</h5>
                                            <p>С помощью коуча, выполняя практические задания, поймете как и где применить свои
                                                таланты</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>9</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Первичный поиск отраслей"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы научитесь анализировать рынок и находить варианты отраслей, подходящие именно вам"
                                class="calendar-date">
                                <p>10</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Первичный поиск отраслей</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы научитесь анализировать рынок и находить варианты отраслей, подходящие именно
                                                вам</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Первичный поиск отраслей</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы научитесь анализировать рынок и находить варианты отраслей, подходящие именно
                                                вам</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#IamRemarkable"
                                data-tooltip-text="Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной среде, чтобы избавиться от блоков, мешающих вашей карьере"
                                class="calendar-date">
                                <p>11</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#IamRemarkable</h5>
                                            <p>Прокачивайте навыки самопрезентации с помощью инструментов Google в комфортной
                                                среде, чтобы избавиться от блоков, мешающих вашей карьере</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Офлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="#light_network"
                                data-tooltip-text="Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие карьерные вопросы"
                                class="calendar-date">
                                <p>12</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Офлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Офлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>#light_network</h5>
                                            <p>Заводите новые знакомства и расширяйте сеть контактов на офлайн-встрече клубовцев
                                                в Москве — в идеальном месте, чтобы «развиртуализироваться» и обсудить волнующие
                                                карьерные вопросы</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 – 14:00"
                                data-tooltip-header="14#IamRemarkable"
                                data-tooltip-text="Усилите навыки самопродвижения и самопрезентации и разберете предубеждения, которые вам мешают заявить о себе"
                                class="calendar-date">
                                <p>13</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                class="calendar-date">
                                <p>14</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Ценности: опора профессионального развития"
                                data-tooltip-text="С помощью коуча научитесь выявлять свои ценности и использовать это в рабочих и личных ситуациях"
                                class="calendar-date">
                                <p>15</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Ценности: опора профессионального развития</h5>
                                            <p>С помощью коуча научитесь выявлять свои ценности и использовать это в рабочих и
                                                личных ситуациях</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>16</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Валидация карьерных гипотез"
                                data-tooltip-text="Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с учетом созданных вами гипотез"
                                class="calendar-date">
                                <p>17</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>18</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык владения английским языком и чувствовать себя уверенно в любых ситуациях, даже работая в международной компании"
                                class="calendar-date">
                                <p>19</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 – 14:00"
                                data-tooltip-header="14#IamRemarkable"
                                data-tooltip-text="Усилите навыки самопродвижения и самопрезентации и разберете предубеждения, которые вам мешают заявить о себе"
                                class="calendar-date">
                                <p>20</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Глубокое исследование рынка и формирование гипотез"
                                data-tooltip-text="Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых возможна ваша реализация"
                                class="calendar-date">
                                <p>21</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Глубокое исследование рынка и формирование гипотез</h5>
                                            <p>Практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором
                                                вы освоите инструмент поиска и выделите топ-3 профессий (ролей), в которых
                                                возможна ваша реализация</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Мотиваторы: ускорители вашей карьеры"
                                data-tooltip-text="Поймете, что такое мотиваторы, как их выявлять и применять в работе, чтобы сделать её приятнее и эффективнее"
                                class="calendar-date">
                                <p>22</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мотиваторы: ускорители вашей карьеры</h5>
                                            <p>Поймете, что такое мотиваторы, как их выявлять и применять в работе, чтобы
                                                сделать её приятнее и эффективнее</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="18:00 МСК" data-tooltip-header="Мастермайнд"
                                data-tooltip-text="Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к желаемому результату"
                                class="calendar-date">
                                <p>23</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермайнд</h5>
                                            <p>Получите свежий взгляд на вашу карьерную ситуацию от других участников клуба. Так
                                                вы сможете увидеть ее по-новому и лучше понять, какие действия приблизят вас к
                                                желаемому результату</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Валидация карьерных гипотез"
                                data-tooltip-text="Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с учетом созданных вами гипотез"
                                class="calendar-date">
                                <p>24</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <p>25</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="12:00 МСК" data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Прокачайте разговорный английский, обсуждая с одногруппниками актуальные карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским языком одновременно"
                                class="calendar-date">
                                <p>26</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Прокачайте разговорный английский, обсуждая с одногруппниками актуальные
                                                карьерные темы. Обменивайтесь опытом и усиливайте навык владения английским
                                                языком одновременно</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div data-tooltip-category="" data-tooltip-name="Воркшоп" data-tooltip-time="12:00 – 14:00"
                                data-tooltip-header="14#IamRemarkable"
                                data-tooltip-text="Усилите навыки самопродвижения и самопрезентации и разберете предубеждения, которые вам мешают заявить о себе"
                                class="calendar-date">
                                <p>27</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Воркшоп</p>
                                            <p>12:00 – 14:00</p>
                                        </div>
                                        <div>
                                            <h5>14#IamRemarkable</h5>
                                            <p>Усилите навыки самопродвижения и самопрезентации и разберете предубеждения,
                                                которые вам мешают заявить о себе</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="network" data-tooltip-name="Онлайн-встреча"
                                data-tooltip-time="19:00 МСК" data-tooltip-header="Книжный клуб"
                                data-tooltip-text="Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями"
                                class="calendar-date">
                                <p>28</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>19:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Книжный клуб</h5>
                                            <p>Участвуйте в обсуждении с одноклубовцами заранее выбранной и прочитанной
                                                нон-фикшн книги, чтобы попрактиковаться в дискуссии и обменяться мнениями</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="teach" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Баланс на работе и в жизни: миф или реальность?"
                                data-tooltip-text="Сделаете упражнения, которые помогут понять, на каких аспектах карьеры и жизни стоит сфокусироваться прямо сейчас, чтобы привести жизнь и работу в баланс"
                                class="calendar-date">
                                <p>29</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Баланс на работе и в жизни: миф или реальность?</h5>
                                            <p>Сделаете упражнения, которые помогут понять, на каких аспектах карьеры и жизни
                                                стоит сфокусироваться прямо сейчас, чтобы привести жизнь и работу в баланс</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="18:00 МСК"
                                data-tooltip-header="Мастермаинд"
                                data-tooltip-text="Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять топливом вашу карьерную ракету"
                                class="calendar-date">
                                <p>30</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>18:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Мастермаинд</h5>
                                            <p>Получайте поддержку единомышленников, генерируйте идеи в группе и обменивайтесь
                                                мнениями, чтобы находить лучшие решения в любой карьерной ситуации и наполнять
                                                топливом вашу карьерную ракету</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Практикум" data-tooltip-time="20:00 МСК"
                                data-tooltip-header="Валидация карьерных гипотез"
                                data-tooltip-text="Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви», на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с учетом созданных вами гипотез"
                                class="calendar-date">
                                <p>31</p>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Практикум</p>
                                            <p>20:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Валидация карьерных гипотез</h5>
                                            <p>Завершающий практикум фокус-кэмпа «Real me.Надо: третий элемент работы по любви»,
                                                на котором СЕО Careerum Ольга Лермонтова расскажет, как двигаться дальше, с
                                                учетом созданных вами гипотез</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="" data-tooltip-time="" data-tooltip-header=""
                                data-tooltip-text="" class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p></p>
                                            <p></p>
                                        </div>
                                        <div>
                                            <h5></h5>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-tooltip-category="" data-tooltip-name="Онлайн-встреча" data-tooltip-time="12:00 МСК"
                                data-tooltip-header="Английский разговорный клуб, уровень B2+"
                                data-tooltip-text="Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык владения английским языком и чувствовать себя уверенно в любых ситуациях, даже работая в международной компании"
                                class="calendar-date">
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="calendar-tooltip"
                                    style="opacity: 0; transform: translate3d(0px, -10px, 0px); pointer-events: none;">
                                    <div>
                                        <div>
                                            <p>Онлайн-встреча</p>
                                            <p>12:00 МСК</p>
                                        </div>
                                        <div>
                                            <h5>Английский разговорный клуб, уровень B2+</h5>
                                            <p>Общайтесь на карьерные темы в участниками языкового клуба, чтобы улучшить навык
                                                владения английским языком и чувствовать себя уверенно в любых ситуациях, даже
                                                работая в международной компании</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Обучение</p>
                    </div>
                    <div>
                        <p>Welcome to Careerum</p>
                    </div>
                    <div>
                        <p>Нетворкинг</p>
                    </div>
                    <div>
                        <p>Офлайн-ивент</p>
                    </div>
                </div>
            </div>
        </div>
                `,
            }
        }
    });


}
